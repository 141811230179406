export class SearchResult {
  private collapsed_size: number = 3;
  private pre = 15;
  private post = 25;
  public searchTerm: string;
  public title: string;
  public path: string;
  public occurances: number = 0;
  public previews: string[] = [];
  public titles: boolean[] = [];
  public collapsedPreviews: string[] = [];
  public collapsable: boolean = false;
  public collapsed: boolean = true;

  constructor(search: string, data?: any, tag?: any) {
    this.searchTerm = search;
    if(data?.isTabbed) {
        this.title = `${data?.parentTitle} (${data?.title})`;
        this.path = data?.url;
    } else {
      this.title = data?.title;
      this.path = data?.url;
    }
    if (data?.title?.toLowerCase().includes(search.toLowerCase())) {
      this.previews.push(this.title);
      this.occurances++;
      this.titles.push(true);
    }
    if (data?.text) {
      const pvs = this.getPreviews(
        search,
        data.text,
        tag
      );
      pvs.forEach((pv) => {
        this.occurances++;
        this.previews.push(pv);
        this.titles.push(false);
      });
      this.collapsed = this.previews.length > this.collapsed_size;
      this.collapsable = this.previews.length > this.collapsed_size;
      for (
        var i = 0;
        i < this.previews.length && i < this.collapsed_size;
        i++
      ) {
        this.collapsedPreviews.push(this.previews[i]);
      }
    }
  }

  getIndiciesOf(searchStr: string, str: string): Array<number> {
    var indices: Array<number> = [];
    var startIndex = 0;
    var index = 0;
    const searchStrLen = searchStr.length;
    if(searchStrLen > 0) {
      while((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
      }
    }
    return indices;
  }

  findWordBoundary(str: string, index: number, direction: number): number {
    while((index > 0) && (index < str.length) && (str[index] != ' ')) {
      index = index + direction;
    }
    return index;
  }

  removeLinks(text: string): string {
    let s = text.indexOf('[');
    let e = text.indexOf(']');
    if((s > 0) && (e > s)) {
      text = text.substring(0,s-1) + text.substring(e + 1);
      text = this.removeLinks(text);
    }
    return text;
  }

  getPreviews(
    search: string,
    content: string,
    tag?: any
  ): string[] {
    const openTag = tag?.open ? tag?.open : '';
    const closeTag = tag?.close ? tag?.close : '';
    var results: string[] = [];

    var cnt = content;
    cnt = cnt.replace(/(\r\n|\n|\r)/gm, ' ');
    cnt = cnt.replace(/\s+/g,' ');
    cnt = this.removeLinks(cnt);

    const occ = this.getIndiciesOf(search.toLowerCase(), cnt.toLowerCase());
    occ.forEach(index => {
      var st = index - this.pre >= 0 ? index - this.pre : 0;
      st = this.findWordBoundary(cnt, st, -1);
      var en =
        index + this.post < cnt.length ? index + this.post : cnt.length;
      en = this.findWordBoundary(cnt, en, 1);
      var pv = cnt.substring(st, en);
      st = pv.toLowerCase().indexOf(search.toLowerCase());
      en = st + search.length;
      pv = `${pv.substring(0,st)}${openTag}${pv.substring(st,en)}${closeTag}${pv.substring(en)}`;
      results.push(pv);
    });

    return results;
  }
}
