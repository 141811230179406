<div class="posts uilab-layout-container">
  <app-page-blockframe *ngIf="loading"></app-page-blockframe>
  <div *ngIf="!loading" class="uilab-main-canvas">
    <div class="page-title">
      <h1>What's new</h1>
      <img [src]="headerImage">
    </div>
    <div *ngFor="let post of posts" class="post">
      <h2>{{post.title}}</h2>
      <app-inner-content [content]="post.content"></app-inner-content>
    </div>
  </div>
</div>
<app-footer></app-footer>