import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SearchResult } from 'src/app/models/searchresult.interface';
import { environment } from 'src/environments/environment';
import { PreferencesService } from '../preferences/preferences.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public searching = new Subject<boolean>();
  public error: any;
  public results = new Subject<SearchResult>();

  constructor(private preferences: PreferencesService) { }

  async getPages() {
    try {
      let searchUrl = `${environment.cdncms}${environment.version}/adsCMS-search.json`;
      const response = await fetch(searchUrl);
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(`Error: ${err}`);
      return null;
    }
  }

  async searchContent(search: string, tag: any) {
    if(environment.version == 'preview') {
      this.error = 'Cannot search preview';      
    } else {
      this.searching.next(true);
      this.error = '';
      const data = await this.getPages();
      if(data?.pages) {
        data.pages.forEach((page: any) => {
          const sr = new SearchResult(search, page, tag);
          if (sr.occurances > 0) {
            this.results.next(sr);
          }
        });
      }
    }
    this.searching.next(false);
  }
}
