import { Injectable } from '@angular/core';
import { Apollo, ApolloBase, gql } from 'apollo-angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApolloPostsService {
  public loading = new Subject<boolean>();
  public error: any;
  public loaded = new CustomEvent("pageloaded");
  public data: any;
  private apollo: ApolloBase;

  constructor(private apolloProvider: Apollo) {
    this.apollo = this.apolloProvider.use('cms');
  }

  getData() {
    this.loading.next(true);
    this.apollo
      .watchQuery({
        query: gql`
        {
          posts {
            nodes {
              date
              status
              title
              categories {
                nodes {
                  name
                }
              }
              isContentNode
              content
            }
          }
        }`,
      })
      .valueChanges.subscribe((result: any) => {
        this.error = result.error;
        if (!result.loading) {
          this.data = result.data;
          this.loading.next(false);
        }
      });
  }
}
