import { Pipe, PipeTransform } from '@angular/core';
import { FilterChangeArgs } from 'src/app/models/filterChangeArgs.interface';
import { AppIcon } from 'src/app/models/appicon.interface';

@Pipe({
  name: 'appiconFilter',
  pure: false
})
export class AppiconFilterPipe implements PipeTransform {

  searchNewIndex(arr: Array<AppIcon>, newIcon: AppIcon): number {
    if(arr.length == 0) {
      return 0;
    }
    var i = 1;
    while(i < arr.length && !(arr[i].name > newIcon.name && arr[i - 1].name <= newIcon.name)) {
        i++;
    }
    return i;
  }

  transform(icons: Array<AppIcon>, args: FilterChangeArgs): Array<AppIcon> {
    var results = new Array<AppIcon>();
    if(icons) {
      icons.forEach(icon => {
        if(this.isFiltered(icon, args)) {
          results.splice(this.searchNewIndex(results, icon), 0, icon);
        }
      });
    }
    return results;
  }

  isFiltered(icon: AppIcon, arg: FilterChangeArgs): boolean {
    var result = true;
    
    let hasName = (icon.name && icon.name.length > 0);
    
    let matchName = (hasName && icon.name.toLowerCase().includes(arg.filter.toLowerCase()));
    
    // Filter Check
    if (!matchName) { 
      result = false; 
    }
    return result;
  }
}
