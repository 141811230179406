<header class="top-banner">
    <svg viewBox="0 0 205.288 46.77">
      <g transform="translate(0 46.77)">
        <path id="path14" d="M90.718-.527V-6.094a.526.526,0,0,1,.527-.526h29.993a.526.526,0,0,1,.527.526V-.527a.527.527,0,0,1-.527.527H91.244a.527.527,0,0,1-.527-.527" fill="#3d1152"/>
        <path id="path16" d="M90.718-20.6v-5.567a.526.526,0,0,1,.527-.527h29.993a.527.527,0,0,1,.527.527V-20.6a.527.527,0,0,1-.527.527H91.244a.526.526,0,0,1-.527-.527" fill="#3d1152"/>
        <path id="path18" d="M90.718-40.676v-5.566a.526.526,0,0,1,.527-.527h29.993a.527.527,0,0,1,.527.527v5.566a.527.527,0,0,1-.527.528H91.244a.527.527,0,0,1-.527-.528" fill="#3d1152"/>
        <path id="path20" d="M50.15-46.447,62.9-17.288a.527.527,0,0,0,.964,0l12.6-29.159a.524.524,0,0,1,.482-.311h6.133a.529.529,0,0,1,.481.743L63.863-.312a.527.527,0,0,1-.963,0L43.058-46.02a.526.526,0,0,1,.481-.741h6.129a.527.527,0,0,1,.482.313" fill="#3d1152"/>
        <path id="path22" d="M21-16.658l5.537-12.814a.527.527,0,0,1,.964,0l5.6,12.814ZM47.343-.741,27.5-46.448a.527.527,0,0,0-.963,0L13.7-16.658H.467a.468.468,0,0,0-.429.653L2.51-10.292a.422.422,0,0,0,.388.254h7.949l-4,9.292A.528.528,0,0,0,7.324,0h6.133a.527.527,0,0,0,.482-.311l4.2-9.724H36L40.25-.314A.528.528,0,0,0,40.732,0h6.129a.527.527,0,0,0,.482-.741" fill="#3d1152"/>
        <path id="path24" d="M135.45-46.447,148.2-17.288a.526.526,0,0,0,.963,0l12.6-29.159a.535.535,0,0,1,.481-.323H168.4a.52.52,0,0,1,.481.733L149.162-.312a.526.526,0,0,1-.961,0L128.358-46.02a.527.527,0,0,1,.482-.741h6.128a.528.528,0,0,1,.483.313" fill="#3d1152"/>
        <path id="path26" d="M178.9-16.658l5.541-12.823a.527.527,0,0,1,.964,0l5.607,12.823ZM205.242-.75,185.4-46.457a.527.527,0,0,0-.963,0L164.721-.733A.52.52,0,0,0,165.2,0h6.155a.538.538,0,0,0,.483-.323l4.2-9.715H193.9L198.15-.323a.525.525,0,0,0,.481.314h6.129a.527.527,0,0,0,.481-.741" fill="#3d1152"/>
      </g>
    </svg>
  </header>

  <article>

      <header>
        <h1>Design, Brand, Build</h1>
        <br/>
        <p>Creating a consistent end-to-end user experience across AVEVA's digital space</p>
      </header>

      <section class="signpost">

        <button class="entrance" (click)="toMarketing()">Brand websites</button>
        <button class="entrance" (click)="toBuild()">Build applications</button>

      </section>

      <img width="100%" [src]="splash" alt="Splash"/>

    </article>
    <footer>
      <a class="uilab-button" href="https://aveva.oak.com/Home/Index/cad43b71-c18d-421b-a2fc-5488fc6a4851">
        Read the executive summary of the AVEVA Design System on OneSpace
      </a>
    </footer>