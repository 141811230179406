import { ElementRef, Injectable } from '@angular/core';
import { enumVersions } from '../../shared/constants/version.constant';
import { environment } from '../../../environments/environment';
import {
  enumImage,
  imageDomainToIgnore,
} from '../../shared/constants/image.constant';

@Injectable({
  providedIn: 'root',
})
export class PathsService {
  private readonly base: string;
  private readonly host: string;
  private readonly imageDomain = `${environment.cdncms}${environment.version}/images/`;

  constructor() {
    this.base = '/' + environment.base;
    const parsedUrl = new URL(window.location.href);
    this.host = parsedUrl.origin;

    if (environment.version === enumVersions.preview) {
      this.imageDomain = `${environment.cms.imgRoot}`;
    }
  }

  cleanPage(
    version: string,
    content: string,
    url: string,
    tab: string,
    paths: boolean
  ): string {
    var spn = document.createElement('span');
    var ele = new ElementRef(spn);
    try {
      ele.nativeElement.innerHTML = content;
      ele = this.addSectionHeaders(ele, url, tab);
      ele = this.fixRoutingOnInternalLinks(ele);
      if (paths) {
        ele = this.fixImagePaths(ele);
        ele = this.fixDownloadPaths(version, ele);
      }
      return ele.nativeElement.innerHTML;
    } catch (e) {
      if (ele.nativeElement.innerHTML == '') {
        console.error(`${e}\nCMS: ${content}`);
        return '';
      } else {
        console.error(`${e}\nBDY: ${ele.nativeElement.innerHTML}`);
        return '';
      }
    }
  }

  fixRoutingOnInternalLinks(ele: ElementRef): ElementRef {
    // Fix routing of internal links
    const sel = 'a[href^="' + environment.cms.href + '"]';
    var els = ele.nativeElement.querySelectorAll(sel);
    for (var idx = 0; idx < els.length; idx++) {
      var el = els[idx] as any;
      var linkHref = el.href;
      linkHref = linkHref.endsWith('/') ? linkHref.slice(0, -1) : linkHref;
      linkHref = linkHref.replace(environment.cms.href, '/' + environment.base);
      el.href = linkHref;
    }
    return ele;
  }

  fixImageHeaderPath(version: string, path: string): string {
    if (path) {
      if (path.startsWith('/wp-content')) {
        path = path.substring(1);
        if (version == 'preview') {
          path = environment.cms.imgRoot + path;
        } else {
          path = environment.cdncms + version + '/headerimages/' + path;
        }
      }
    }
    return path;
  }

  /**
   *
   * @param {ElementRef} ele - This is the angular element reference
   * @returns {ElementRef} ele - updated element reference
   */
  fixImagePaths(ele: ElementRef): ElementRef {
    const docIllustrations = ele.nativeElement.getElementsByTagName('img');

    for (const element of docIllustrations) {
      const img = element;
      const imgSrc = img.getAttribute('src');
      const imgSrcSet = img.getAttribute('srcset');
      let imgSrcSetData = imgSrcSet;
      let imgSrcData = imgSrc;

      if (imgSrc) {
        imgSrcData = this.processImageString(imgSrcData);
        img.setAttribute('src', imgSrcData);
      }

      if (imgSrcSet) {
        imgSrcSetData = this.processSrcSetData(imgSrcSet);

        img.setAttribute('srcset', imgSrcSetData);
      }
    }
    return ele;
  }

  fixDownloadPaths(version: string, ele: ElementRef): ElementRef {
    var atags = ele.nativeElement.getElementsByTagName('a');
    // var docIllustrations = ele.nativeElement.querySelectorAll('img[src^='/wp-content']');
    for (var i = 0; i < atags.length; i++) {
      var atag = atags[i];
      if (atag.href.startsWith(this.host + '/wp-content')) {
        var strThisSrc = atag.getAttribute('href')?.substring(1);
        atag.setAttribute('href', environment.cms.imgRoot + strThisSrc);
      }
    }
    return ele;
  }

  addSectionHeaders(ele: ElementRef, path: string, tab: string): ElementRef {
    // Add section links in content
    var toc = [];
    var sectionHeaders = ele.nativeElement.getElementsByTagName('h2');
    if (sectionHeaders.length > 0) {
      for (var i = 0; i < sectionHeaders.length; i++) {
        var thisEl = sectionHeaders[i];
        const title = thisEl.innerHTML;
        var hash = this.createSectionId(thisEl.innerHTML);
        thisEl.setAttribute('id', hash);
        var addAnchor = document.createElement('a');
        addAnchor.setAttribute('title', 'Link to section');
        if (path.endsWith('/')) {
          path = path.slice(0, -1);
        }
        var href = this.base + path + '#' + hash;
        if (tab) {
          href = this.base + path + '/' + tab + '#' + hash;
        }
        addAnchor.setAttribute('href', href);
        addAnchor.classList.add('section-link');
        thisEl.append(addAnchor);
        thisEl.classList.add('has-section-link');
        toc.push({ title: title, link: href });
      }
      if (sectionHeaders.length > 1) {
        // Add TOC
        // let tocEl = `<div class="toc_header"><a>In this section</a><div class="toc_list">`;
        let tocEl = `<div class="toc_list">`;
        toc.forEach((t: any) => {
          tocEl += `<a href="${t.link}">${t.title}</a><br />`;
        });
        // Add Entries
        // tocEl += `</div></div>`
        tocEl += `</div>`;
        tocEl += ele.nativeElement.innerHTML;
        ele.nativeElement.innerHTML = tocEl;
      }
    }
    return ele;
  }

  createSectionId(id: string): any {
    if (id) {
      return (
        'section--' +
        id
          .toLowerCase()
          .replace(/[^0-9a-zA-Z]+/gi, '-')
          .trim()
          .replace(/ /gi, '-')
      );
    }
    return false;
  }

  /**
   * Checks if path starts with http, if it starts with http
   *
   * @param path
   * @returns {string}
   */
  startsWithHttp(path: string) {
    if (path.startsWith('http')) {
      return this.removeStringBeforeWpContent(path);
    }

    return path;
  }

  /**
   * Please note that the domain should contain a trailing slash
   * @example - https:www.google.com/
   *
   * @param {string} path - This is the path before the domain
   * @returns {string} https:www.google.com/file.jpg
   */
  addDomainToPath(path: string): string {
    return `${this.imageDomain}${path}`;
  }

  /**
   *
   * @param {string} srcSet - This is a comma separated string
   *
   * @example
   * https://adswp-a556d4e1987fefaa-endpoint.azureedge.net/blobadswpc786d65151/wp-content/uploads/sites/2/2024/09/user-research-interview-1536x1279.png 1536w, https://adswp-a556d4e1987fefaa-endpoint.azureedge.net/blobadswpc786d65151/wp-content/uploads/sites/2/2024/09/user-research-interview-2048x1705.png 2048w
   *
   * @returns
   */
  processSrcSetData(srcSet: string): string {
    const ARR = srcSet.split(', ');
    let imgSrcData = '';

    const DATA = ARR.map((path) => {
      imgSrcData = path;
      imgSrcData = this.processImageString(imgSrcData);

      return imgSrcData;
    });

    return DATA.join(', ');
  }

  /**
   *
   * @param {string} imgPath - The image path
   * @returns a string with a domain
   */
  processImageString(imgPath: string): string {
    let imgSrcData = imgPath;
    if (!imageDomainToIgnore.some((domain) => imgPath.includes(domain))) {
      imgSrcData = this.startsWithHttp(imgSrcData);
      imgSrcData = this.replaceDashWpContent(imgSrcData);
      imgSrcData = this.addDomainToPath(imgSrcData);
    }

    return imgSrcData;
  }

  /**
   * Check if any of the links has a // in the url and replaces it with a /
   * This was causing a few of the images to not render on the website
   *
   * @param {string} path - The string that needs cleaning
   * @returns {string}
   */
  replaceDashWpContent(path: string): string {
    return path
      .replace(`//${enumImage.wpContent}`, enumImage.wpContent)
      .replace(`/${enumImage.wpContent}`, enumImage.wpContent);
  }

  /**
   * This method will split on '/wp-content' to create and array of 2 items
   *
   * @param {string} path
   * @returns {string}
   */
  removeStringBeforeWpContent(path: string): string {
    const DATA = path;
    const ARR = DATA.split(`/${enumImage.wpContent}`);

    if (ARR?.[1]) {
      // correctWpContentString: added wp-content back into the url
      return `${enumImage.wpContent}${ARR?.[1]}`;
    }

    return DATA;
  }
}
