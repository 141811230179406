import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-icon-theme',
  templateUrl: './icon-theme.component.html',
  styleUrls: ['./icon-theme.component.scss']
})
export class IconThemeComponent implements OnInit {
  public lightTheme = true;
  public darkTheme = false;
  public interactive = false;

  public disabled = 'false';

  public iconTheme = 'light';

  @Output() onThemeChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  singleChange() {
    var theme = '';
    const suffix = (this.disabled == 'true') ? '-disabled' : '';
    if(this.lightTheme) theme = 'light';
    if(this.darkTheme) theme = 'dark';
    if(this.interactive) theme = 'interaction';
    this.iconTheme = theme + suffix;
    this.onThemeChange.emit(this.iconTheme);
  }

  select(theme: string) {
    switch(theme) {
      case 'light':
        this.lightTheme = true;
        this.darkTheme = false;
        this.interactive = false;
        break;
      case 'dark':
        this.lightTheme = false;
        this.darkTheme = true;
        this.interactive = false;
        break;
      case 'interactive':
        this.lightTheme = false;
        this.darkTheme = false;
        this.interactive = true;
        break;
    }
    this.singleChange();
  }

  disable() {
    if(this.disabled == 'true') {
      this.disabled = 'false';
    } else {
      this.disabled = 'true';
    }
    this.singleChange();
  }
}
