import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconsService } from 'src/app/services/icons/icons.service';
import { IconStyle } from 'src/app/models/iconstyle.interface';
import { Category } from 'src/app/models/category.interface';
import { Size } from 'src/app/models/iconSize.interface';
import { FilterChangeArgs } from 'src/app/models/filterChangeArgs.interface';

@Component({
  selector: 'app-icon-chips',
  templateUrl: './icon-chips.component.html',
  styleUrls: ['./icon-chips.component.scss']
})
export class IconChipsComponent implements OnInit {
  @Input() filterOpts: FilterChangeArgs = new FilterChangeArgs();
  public styles: Array<IconStyle> = [];
  public categories: Array<Category> = [];
  public sizes: Array<Size> = [];
  public selectedStyle: string = '';
  public selectedCategory: string = '';
  @Output() onFilterChange: EventEmitter<FilterChangeArgs> = new EventEmitter<FilterChangeArgs>();

  constructor(public iconService: IconsService) {
    this.iconService.sloading.subscribe(value => {
      if(!value) {
        this.styles = [...this.iconService.iconstyles];
        this.selectedStyle = this.styles[0].iconstyle;
        this.filterOpts.iconstyle = this.selectedStyle;
      }
    });
    this.iconService.cloading.subscribe(value => {
      if (!value) {
        this.categories = [...this.iconService.categories];
        this.categories.unshift(new Category({category: 'All'}, true));
        this.selectedCategory = this.categories[0].category;
        this.filterOpts.category = this.selectedCategory;
      }
    });
    this.iconService.zloading.subscribe(value => {
      if (!value) {
        this.sizes = [...this.iconService.sizes];
      }
    });
  }

  ngOnInit(): void {}

  showStyle(event: any) {
    this.filterOpts.iconstyle = event;
    this.selectedStyle = event;
    this.emitChange();
  }

  showCategory(event: any) {
    this.filterOpts.category = event.value;
    this.emitChange();
  }

  showSize(event: any) {
    this.filterOpts.size = event.value;
    this.emitChange();
  }

  emitChange():void {
    this.onFilterChange.emit(this.filterOpts);
  }

}
