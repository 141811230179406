import { Component, ElementRef, inject, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { PageChild } from 'src/app/models/page.interface';
import { environment } from 'src/environments/environment';
import { PathsService } from 'src/app/services/paths/paths.service';

@Component({
  selector: 'app-tabbed',
  templateUrl: './tabbed.component.html',
  styleUrls: ['./tabbed.component.scss'],
})
export class TabbedComponent implements OnInit {
  @Input() pages: PageChild[] = [];
  @Input() page: string = '';
  @Input() selectedIndex: number = 0;

  private pathsService = inject(PathsService);

  constructor(private location: Location, private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  selectedIndexChange(event: any): void {
    this.selectedIndex = event;
    const rte = `${environment.base}${this.page}${
      this.pages[this.selectedIndex].slug
    }`;
    this.location.go(rte);
  }

  safeUrl(url: string): any {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  setAbsoluteImagePaths(content: string): string {
    const spn = document.createElement('span');
    let ele = new ElementRef(spn);
    ele.nativeElement.innerHTML = content;
    ele = this.pathsService.fixDownloadPaths(environment.version, ele);
    ele = this.pathsService.fixImagePaths(ele);

    return ele.nativeElement.outerHTML.toString();
  }
}
