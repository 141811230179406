import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { Icon } from 'src/app/models/icon.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IconThemeComponent } from '../icon-theme/icon-theme.component';
import { DownloadService } from 'src/app/services/icons/download.service';
import { MatDialog } from '@angular/material/dialog';
import { CantdownloadComponent } from 'src/app/dialogs/cantdownload/cantdownload.component';

@Component({
  selector: 'app-icon-details',
  templateUrl: './icon-details.component.html',
  styleUrls: ['./icon-details.component.scss'],
})
export class IconDetailsComponent implements OnInit {
  @ViewChild('colorTheme') colorTheme!: IconThemeComponent;

  public _icon: Icon;
  @Input() set icon(value: Icon) {
    this._icon = value;
    if (
      this._icon &&
      this._icon.category &&
      this._icon.name &&
      this.colorTheme
    ) {
      this.exampleCDN = this.download.cdnURL(
        'svg',
        this.style,
        '32',
        this.colorTheme.iconTheme,
        this._icon.category.toLowerCase(),
        this._icon.name.toLowerCase()
      );
    }
  }
  get icon(): Icon {
    return this._icon;
  }
  @Input() style: string = 'outlined';

  public exampleCDN: string = '';

  constructor(
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private download: DownloadService,
    public dialog: MatDialog,
  ) {
    const emptyIcon = {
      name: '',
    };
    this._icon = new Icon(emptyIcon);
  }

  ngOnInit(): void {}

  onThemeChange(): void {
    this.exampleCDN = this.download.cdnURL(
      'svg',
      this.style,
      '32',
      this.colorTheme.iconTheme,
      this.icon.category.toLowerCase(),
      this.icon.name.toLowerCase()
    );
  }

  copyCode(): void {
    const code = '<uilab-icon icon="' + this.icon.name + '"></uilab-icon>';
    this.clipboard.copy(code);
    this.notify('Copied code to clipboard');
  }

  copySVG(): void {
    this.clipboard.copy(this.getSVG());
    this.notify('Copied svg to clipboard');
  }

  copyLink(): void {
    const link = this.exampleCDN;
    this.clipboard.copy(link);
    this.notify('Copied link to clipboard');
  }

  cantdownload() {
    this.dialog.open(CantdownloadComponent, {
      width: '500px',
    });
  }

  downloadsvg32() {
    if (this.download.canDownload()) {
      this.download.saveSVG(
        this.style,
        '32',
        this.icon.category.toLowerCase(),
        this.icon.name.toLowerCase()
      );
    } else {
      this.cantdownload();
    }
  }
  downloadsvg24() {
    if (this.download.canDownload()) {
      this.download.saveSVG(
        this.style,
        '24',
        this.icon.category.toLowerCase(),
        this.icon.name.toLowerCase()
      );
    } else {
      this.cantdownload();
    }
  }

  downloadpng32() {
    if (this.download.canDownload()) {
      this.download.savePNG(
        this.style,
        '32',
        this.colorTheme.iconTheme,
        this.icon.category.toLowerCase(),
        this.icon.name.toLowerCase()
      );
    } else {
      this.cantdownload();
    }
  }
  downloadpng24() {
    if (this.download.canDownload()) {
      this.download.savePNG(
        this.style,
        '24',
        this.colorTheme.iconTheme,
        this.icon.category.toLowerCase(),
        this.icon.name.toLowerCase()
      );
    } else {
      this.cantdownload();
    }
  }
  downloadpng16() {
    if (this.download.canDownload()) {
      this.download.savePNG(
        this.style,
        '16',
        this.colorTheme.iconTheme,
        this.icon.category.toLowerCase(),
        this.icon.name.toLowerCase()
      );
    } else {
      this.cantdownload();
    }
  }

  downloadico() {
    if (this.download.canDownload()) {
      this.download.saveICO(
        this.style,
        this.colorTheme.iconTheme,
        this.icon.category.toLowerCase(),
        this.icon.name.toLowerCase()
      );
    } else {
      this.cantdownload();
    }
  }

  getSVG(): string {
    return this.icon.svg;
  }

  notify(msg: string): void {
    this.snackBar.open(msg, 'OK', {
      duration: 3000,
      horizontalPosition: 'center'
    });
  }
}
