import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Page, PageChild } from '../../models/page.interface';
import { PageService } from '../../services/page/page.service';
import { PathsService } from '../../services/paths/paths.service';
import { PreferencesService } from '../../services/preferences/preferences.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: '[appContent]',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, OnDestroy {
  public tabTitle: string = 'AVEVA Design System / Build';
  public title: string = '';
  public headerImage: boolean = false;
  public headerImageSourceUrl: string = '';
  public headerImageAltText: string = '';
  public content: string = '';
  public guidanceStatus: string = '';
  public slug: string = '';
  public scopePlatform: string = '';
  public childType: string = '';
  public children: PageChild[] = [];
  public selectedIndex: number = 0;
  public page: string = '';
  public pageData!: Page;
  public type: string = '';
  public iframeUrl: string = '';
  public error: string = '';
  public readonly researchCards = 'research-cards';
  public readonly researchCard = 'research-card';

  public busy: boolean = true;
  domSanitizer: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    public pageService: PageService,
    private pathsService: PathsService,
    private preferences: PreferencesService
  ) {
    this.route.url.subscribe((url) => {
      this.updatePage();
    });
  }

  ngOnInit(): void {
    // this.updatePage();
  }

  updatePage(): void {
    this.pageService.loading.subscribe((value) => {
      this.busy = value;
      const page = this.pageService.page;
      this.pageData = page;
      const error = this.pageService.error;
      if (!this.busy) {
        if (error != null) {
          this.error = error;
        } else {
          if (!page.title) {
            this.error = this.pageService.error;
          } else {
            this.titleService.setTitle(this.tabTitle + ' / ' + page.title);
            this.title = page.title;
            if (page.headerImageSourceUrl != '') {
              this.headerImage = true;
              this.headerImageSourceUrl = this.pathsService.fixImageHeaderPath(
                environment.version,
                page.headerImageSourceUrl
              );
            } else {
              this.headerImage = false;
            }
            this.childType = page.childType;
            this.children = page.children;
            if (this.childType == 'cards') {
              this.children.sort((x, y) => {
                if (x.title < y.title) return -1;
                if (x.title > y.title) return 1;
                return 0;
              });
            }
            this.guidanceStatus = page.guidanceStatus;
            this.slug = page.slug;
            this.scopePlatform = page.scopePlatform;
            this.content = page.content;
            this.selectedIndex = page.tabIndex;
            this.page = page.url;
            this.type = page.type;
            this.iframeUrl = page.exampleUrl;
          }
        }
      }
    });
    this.pageService.getPageContent(this.router.url);
  }

  ngOnDestroy(): void {
    try {
      this.pageService.loading.unsubscribe();
    } catch (err) {
      console.log(err);
    }
  }

  safeUrl(url: string): any {
    if (url) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }
}
