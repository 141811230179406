@if (!isStatic) {
  <a *ngIf="external !='true'" class="uilab-card" [routerLink]="link">
    <div *ngIf="avatarsize == ''" class="uilab-card--thumbnail thumbnail"
         style="background-image:url({{headerImageSourceUrl}});"
    >
    </div>
    <div *ngIf="avatarsize != ''" class="uilab-card--thumbnail thumbnail"
         style="background-image:url({{headerImageSourceUrl}});"
         [style.background-size.px]="avatarsize"
    >
    </div>
    <div class="uilab-card--content">
      <header class="uilab-card--header">
        <h4>{{ title }}</h4>
      </header>
      <div class="uilab-card--description">
        {{ description }}
      </div>
    </div>
  </a>
  <a *ngIf="external == 'true'" class="external uilab-card" [href]="link">
    <div *ngIf="avatarsize == ''" class="uilab-card--thumbnail thumbnail"
         style="background-image:url({{headerImageSourceUrl}});"
    >
    </div>
    <div *ngIf="avatarsize != ''" class="uilab-card--thumbnail thumbnail"
         style="background-image:url({{headerImageSourceUrl}});"
         [style.background-size.px]="avatarsize"
    >
    </div>
    <div class="uilab-card--content">
      <header class="uilab-card--header">
        <h4>{{ title }}</h4>
      </header>
      <div class="uilab-card--description">
        {{ description }}
      </div>
    </div>
  </a>
} @else {
  <div class="uilab-card uilab-card--static">
    <div class="uilab-card--content">
      <header class="uilab-card--header">
        <h4>{{ title }}</h4>
      </header>
      <div class="uilab-card--description" [innerHTML]="content"></div>
    </div>
  </div>
}
