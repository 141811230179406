<div class="uilab-card uilab-card--static">
  <div class="uilab-card--content">
    <header class="uilab-card--header">
      <h4>{{ card.title }}</h4>
    </header>

    <p [innerHTML]="card.summary"></p>

    <article *ngIf="card.date" class="uilab-card--article">
      <strong>Date:</strong> {{ card.date | date : "mediumDate" }}
    </article>

    <article *ngIf="card.product?.joined" class="uilab-card--article">
      <strong>Product:</strong> {{ card.product?.joined }}
    </article>

    <article *ngIf="card.type" class="uilab-card--article">
      <strong>Research type:</strong> {{ card.type.joined }}
    </article>

    <article *ngIf="card.researchers" class="uilab-card--article">
      <strong>Researchers:</strong> {{ card.researchers }}
    </article>

    <footer>
      <p>
        <a
          *ngIf="card.studyReportLink"
          [href]="card.studyReportLink"
          target="_blank"
        >
          Study report
        </a>

        <a
          *ngIf="card.meetingReportLink"
          [href]="card.meetingReportLink"
          target="_blank"
        >
          Meeting report back
        </a>

        <a
          *ngIf="card.prototypeLink"
          [href]="card.prototypeLink"
          target="_blank"
        >
          Prototype
        </a>
      </p>
    </footer>
  </div>
</div>
