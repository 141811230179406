<uilab-icon class="ui-search__prefix-icon" icon="action--search"></uilab-icon>
<ng-content></ng-content>
<uilab-icon-button
  *ngIf="hasContent"
  aria-label="Clear"
  title="Clear"
  class="ui-search__suffix-icon"
  (click)="resetForm()"
>
  <uilab-icon slot="icon" icon="action--close"> </uilab-icon>
</uilab-icon-button>
