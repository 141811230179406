import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchResult } from 'src/app/models/searchresult.interface';
import { SearchService } from 'src/app/services/search/search.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: '[appSearch]',
  templateUrl: './search.page.html',
  styleUrls: ['./search.page.scss'],
})
export class SearchPage implements OnInit {
  public initial: boolean = true;
  public value: any;
  public searched = '';
  public error = '';
  public searchResults: SearchResult[] = [];
  public isSearching: boolean = false;
  public envBase: string = environment.base;
  public loadingColor: string = 'surface';
  private searchElement?: HTMLInputElement;

  constructor(
    private router: Router,
    public searchService: SearchService,
    private route: ActivatedRoute
  ) {
    if (this.route.snapshot.queryParams?.['searchTerm']) {
      this.value = this.route.snapshot.queryParams?.['searchTerm'];
      this.initSearch();
    }

    this.searchService.results.subscribe((result) => {
      this.searchResults.push(result);
    });
    this.searchService.searching.subscribe((value) => {
      this.isSearching = value;
      this.error = this.searchService.error;
    });
  }

  ngOnInit(): void {
    this.searchElement = document.getElementById('search') as HTMLInputElement;
    this.searchElement.addEventListener('input', () => {
      if (this.value == '') {
        this.initial = true;
      }
    });
  }

  navigateTo(url: string, e: Event) {
    e.preventDefault();
    this.router.navigate([`${this.envBase}${url}`]);
  }

  resetForm(): void {
    this.value = '';
    this.initSearch();
    this.initial = true;
  }

  onSubmit(): void {
    this.initSearch();
  }

  initSearch(): void {
    this.searched = this.value;
    this.searchResults = [];
    this.initial = false;
    this.searchService.searchContent(this.value, {
      open: '<strong>',
      close: '</strong>',
    });
  }
}
