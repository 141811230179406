import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IconproductsService {
  public productRepository = `${environment.cdn}icons/products`;
  public products = [];
  public loading = new Subject<Boolean>();

  constructor() { }

  async getProducts() {
    this.loading.next(true);
    try {
      const response = await fetch(`${this.productRepository}/products.json`);
      if(response.ok) {
        const data = await response.json();
        data.sort();
        this.products = data;
        this.loading.next(false);
      } else {
        console.error(`Product fetch error`);
      }
    } catch (err) {
      console.error(err);
    }

  }
}