import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterChangeArgs } from 'src/app/models/filterChangeArgs.interface';


@Component({
  selector: 'app-appicon-filter',
  templateUrl: './appicon-filter.component.html',
  styleUrls: ['./appicon-filter.component.scss']
})
export class AppiconFilterComponent implements OnInit {
  @Output() onFilterChange: EventEmitter<FilterChangeArgs> = new EventEmitter<FilterChangeArgs>();
  @Input() args:FilterChangeArgs = new FilterChangeArgs();
  public value: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  resetForm(): void {
    this.value = '';
    this.initSearch();
  }

  onSubmit(): void {
    this.initSearch();
  }

  FilterChange(args:FilterChangeArgs): void {
    this.args = args;
    this.onFilterChange.emit(this.args);
  }

  initSearch(): void {
    this.args.filter = this.value;
    this.onFilterChange.emit(this.args);
  }

}
