import {Injectable} from '@angular/core';
import {Apollo, ApolloBase, gql} from 'apollo-angular';
import {Subject} from 'rxjs';
import {AppIcon} from 'src/app/models/appicon.interface';

@Injectable({
  providedIn: 'root',
})
export class AppIconsService {
  // Common
  public error = new Subject<boolean>();
  public errorMsg = '';
  // Version information
  public vloading = new Subject<boolean>();
  public version = 'Not loaded';
  public vloaded = new CustomEvent('appiconsloaded');
  // Icons
  public loading = new Subject<boolean>();
  public icons = new Array<AppIcon>();
  public loaded = new CustomEvent('appiconsloaded');

  private apollo: ApolloBase;

  constructor(private apolloProvider: Apollo) {
    this.apollo = this.apolloProvider.use('icons');
  }

  getAll() {
    this.getVersion();
    this.getAppIcons();
  }

  getVersion() {
    this.vloading.next(true);
    this.apollo
      .watchQuery({
        query: gql`
          {
            version
          }
        `,
      })
      .valueChanges.subscribe((result: any) => {
      const data = result?.data;
      if (data) {
        this.version = data.version;
      }
      if (result.error) {
        this.errorMsg = result.errorMsg;
        this.error.next(result.error);
      } else {
        this.errorMsg = '';
        this.error.next(result.error);
      }
      if (!result.loading) {
        dispatchEvent(this.vloaded);
      }
      this.vloading.next(false);
    });
  }

  getAppIcons() {
    this.loading.next(true);
    this.icons = new Array<AppIcon>();
    this.apollo
      .watchQuery({
        query: gql`
          {
            appicons {
              name
              files
            }
          }
        `,
      })
      .valueChanges.subscribe((result: any) => {
      const data = result?.data;
      if (data?.appicons) {
        for (var i = 0; i < data.appicons.length; i++) {
          if (data.appicons[i].name !== 'fonts') {
            const newApp = new AppIcon(data.appicons[i]);
            this.icons.push(newApp);
          }
        }
      }
      if (result.error) {
        this.errorMsg = result.errorMsg;
        this.error.next(result.error);
      } else {
        this.errorMsg = '';
        this.error.next(result.error);
      }
      if (!result.loading) {
        dispatchEvent(this.loaded);
      }
      this.loading.next(false);
    });
  }

  getImgUrl(icon: AppIcon, index: number): string {
    // let url = `${environment.cdn}/icons/applications/${icon.name}/${icon.files[index]}`;
    return icon.files[index];
  }
}
