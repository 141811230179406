import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UilabBaseComponentsModule } from '@uilab/base-components-angular';

@Component({
  selector: 'ui-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports: [NgIf, MatFormFieldModule, UilabBaseComponentsModule],
})
export class SearchUiComponent {
  @Input() hasContent = false;
  @Output() clearSearch = new EventEmitter();

  resetForm() {
    this.clearSearch.emit();
  }
}
