<footer>
  <div class="uilab-card forum" (click)="link('https://teams.microsoft.com/l/team/19%3a8caae470ac5b4facac5ca7d6bdf6b432%40thread.skype/conversations?groupId=570e3b02-9d36-4b36-b99b-fe66e701fce6&tenantId=425a5546-5a6e-4f1b-ab62-23d91d07d893')">
    <div class="uilab-card--content">
      <header class="header">
        <h3 class="subheading-1">Development discussion</h3>
        <uilab-icon class="icon" icon="info--forum"></uilab-icon>
      </header>
      <div class="description body-2">
        Join us on AVEVA Design System on Microsoft Teams to talk about the nuts and bolts of how to use the ADS in your product.
      </div>
    </div>
</div>
<div class="row">
<div class="uilab-card email" (click)="internalLink('/build/contact-us')">
  <div class="uilab-card--content">
    <header class="header">
      <h3>Contact us</h3>
      <uilab-icon class="icon" icon="content--email"></uilab-icon>
    </header>
    <div class="description">
      Contact the UX Team with any queries about the contents of the AVEVA Design System, Icons, User Research/Personas, or any other general UX topics.
    </div>
  </div>
</div>
<div class="uilab-card announcement" (click)="internalLink('/build/introduction/whats-new')">
  <div class="uilab-card--content">
  <header class="header">
    <h3>Feature announcements</h3>
    <uilab-icon class="icon" icon="info--announcement"></uilab-icon>
  </header>
  <div class="description">
    Periodic announcements will be made on Yammer to keep you up to date with newly released features and those on the roadmap.
  </div>
</div>
</div>
</div>
</footer>
