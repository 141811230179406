<main id="page_{{ slug }}" *ngIf="error == ''">
  <section *ngIf="!busy">
    <div class="page-title">
      <h1>{{ title }}</h1>
      <img
        *ngIf="headerImage"
        [src]="headerImageSourceUrl"
        alt="header image"
      />
    </div>

    <app-inner-content [content]="content"></app-inner-content>

    <div *ngIf="childType == 'tabs'">
      <app-tabbed
        [page]="page"
        [pages]="children"
        [selectedIndex]="selectedIndex"
      ></app-tabbed>
    </div>

    <app-cards *ngIf="childType === 'cards'" [pages]="children"></app-cards>

    <app-research-cards
      *ngIf="childType === researchCards"
      [pageChildren]="children"
    ></app-research-cards>

    <app-user-research-detail
      *ngIf="pageData?.type === researchCard"
      [parent]="pageData.parent"
    ></app-user-research-detail>

    <div *ngIf="type == 'whats-new'">
      <app-whats-new></app-whats-new>
    </div>

    <iframe
      class="externalContent"
      *ngIf="type === 'ui' || type === 'demo' || type === 'custom'"
      [src]="safeUrl(iframeUrl)"
      title="external content"
    ></iframe>

    <app-footer></app-footer>
  </section>

  <app-page-blockframe *ngIf="busy"></app-page-blockframe>
</main>
<main id="page_{{ slug }}" *ngIf="error != ''">
  <app-error error="error"></app-error>
</main>
