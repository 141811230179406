import { ResearchCard } from './research.card.interface';

export class PageChild {
  public id: string;
  public order: number;
  public title: string;
  public content: string;
  public guidanceStatus: string;
  public summary: string;
  public type: string;
  public exampleUrl: string;
  public slug: string;
  public scopePlatform: string;
  public headerImageSourceUrl: string;
  public headerImageAltText: string;
  public date?: string;
  public personagroup?: string;
  public researchers?: string;
  public studyreportlink?: string;
  public meetingreportlink?: string;
  public prototypelink?: string;
  public menuOrder?: number;
  public uri?: string;
  public researchtype?: string[];
  public researchproduct?: string[];

  constructor(data?: any) {
    this.id = data?.id;
    this.order = data?.menuOrder;
    this.title = data?.title;
    this.content = data?.content;
    this.guidanceStatus = data?.adsGuidance.status;
    this.summary = data?.adsType.summary;
    this.type = data?.adsType.type;
    this.exampleUrl = data?.adsType?.exampleurl;
    this.slug = data?.slug;
    this.scopePlatform = data?.adsScope.platform;
    this.headerImageAltText = data?.adsType.headerimage
      ? data?.adsType.headerimage.altText
      : '';
    this.headerImageSourceUrl = data?.adsType.headerimage
      ? data?.adsType.headerimage.sourceUrl
      : '';
    this.date = data?.adsType?.date;
    this.personagroup = data?.adsType?.personagroup;
    this.researchers = data?.adsType?.researchers;
    this.studyreportlink = data?.adsType?.studyreportlink;
    this.meetingreportlink = data?.adsType?.meetingreportlink;
    this.prototypelink = data?.adsType?.prototypelink;
    this.menuOrder = data?.adsType?.menuOrder;
    this.uri = data?.adsType?.uri;
    this.researchtype = data?.adsType?.researchtype;
    this.researchproduct = data?.adsType?.researchproduct;
  }
}

export class Page {
  // public id: string;
  public url: string;
  public title: string;
  public content: string;
  public modified: string;
  public guidanceStatus: string;
  public summary: string;
  public type: string;
  public exampleUrl: string;
  public slug: string;
  public scopePlatform: string;
  public childType: string;
  public children: PageChild[] = [];
  public headerImageSourceUrl: string;
  public headerImageAltText: string;
  public tab: string = '';
  public tabIndex: number = 0;
  public parent: any;
  public node!: any;

  constructor(data?: any, tab?: string) {
    // this.id = data?.id;
    this.url = data?.uri;
    this.title = data?.title;
    this.content = data?.content;
    this.modified = data?.modified;
    this.guidanceStatus = data?.adsGuidance?.status;
    this.summary = data?.adsType?.summary;
    this.type = data?.adsType?.type;
    this.exampleUrl = data?.adsType?.exampleurl;
    this.slug = data?.slug;
    this.parent = data?.parent;
    if (data?.headerImageAltText) {
      this.headerImageAltText = data.headerImageAltText;
    } else {
      this.headerImageAltText = data?.adsType?.headerimage
        ? data?.adsType?.headerimage?.altText
        : '';
    }
    if (data?.headerImageSourceUrl) {
      this.headerImageSourceUrl = data.headerImageSourceUrl;
      // console.log(data.headerImageSourceUrl);
    } else {
      this.headerImageSourceUrl = data?.adsType?.headerimage
        ? data?.adsType?.headerimage?.sourceUrl
        : '';
      // console.log(`adsType ${this.headerImageSourceUrl}`);
    }
    this.childType = data?.adsHierarchy?.childPages;
    if (data?.children?.nodes) {
      data?.children?.nodes.forEach((child: any) => {
        const c = new PageChild(child);
        this.children.push(c);
      });
      this.children.sort((firstEl, secondEl) => {
        return firstEl.order - secondEl.order;
      });
    }
    if (tab) {
      this.tab = tab.toLowerCase();
      for (var i = 0; i < this.children.length; i++) {
        let ctab = this.children[i].title.replaceAll(' ', '-').toLowerCase();
        if (ctab == 'ui-icons') {
          ctab = 'system-icons';
        }
        if (this.tab == ctab) {
          this.tabIndex = i;
        }
      }
    }

    this.scopePlatform = data?.adsScope?.platform;
    // console.log(this.scopePlatform);
    // if (data?.adsScope.platform.indexOf(',')) {
    //     this.scopePlatform = data?.adsScope.platform.split(',').join(', ');
    // }
  }
}
