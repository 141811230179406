import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService, User} from '@auth0/auth0-angular';
import {Subject} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public isLoggedIn: boolean = false;
  public isAuthenticated = new Subject<boolean>();
  public profile = new Subject<any>();
  public staticProfile: any = null;

  constructor(public auth: AuthService, private router: Router) {
    this.auth.isAuthenticated$.subscribe(value => {
      this.isLoggedIn = value;
      this.isAuthenticated.next(value);
    });
    this.auth.user$.subscribe(value => {
      if (value) {
        this.staticProfile = value;
        this.profile.next(value);
      }
    });
  }

  async login(path: string) {
    this.checkForNoLogin();
    if (!this.isLoggedIn) {
      if (environment.featureFlags.auth) {
        const callback = window.location.origin + environment.auth.callback;
        localStorage.setItem('route', path);
        //console.log(`Login: ${path}`);
        this.auth.loginWithRedirect({
          authorizationParams: {
            redirect_uri: callback
          },
          appState: { target: path }
        });
      }
    }
  }

  checkForNoLogin() {
    if (!environment.featureFlags.auth) {
      this.isLoggedIn = true;
      this.staticProfile = new User();
      this.staticProfile.name = 'Testing User';
      this.staticProfile.email = 'noreply@aveva.com';
      this.profile.next(this.staticProfile);
      this.isAuthenticated.next(true);
    }
  }

  logout(): void {
    this.isLoggedIn = false;
    this.auth.logout({
      logoutParams: {
        returnTo: environment.auth.returnTo
      }
    });
  }

  async callback() {
    this.auth.handleRedirectCallback();
  }
}
