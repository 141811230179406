<uilab-nav-rail
  #navrail
  id="navRail"
  [ngClass]="busy ? 'noshow' : 'show'"
  class="uilab-nav-rail"
  [items]="navRail"
  [activeRoute]="activeRoute"
  [subMenuType]="'sidesheet'"
  (menuItemSelected)="navTo($event)"
  (collapsedEmitter)="collapsed($event)"
  [collapsed]="isCollapsed"
  [attr.aria-expanded]="isCollapsed"
></uilab-nav-rail>
<app-navrail-blockframe *ngIf="busy"></app-navrail-blockframe>
