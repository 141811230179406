import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  private key_Version = 'version';
  private val_Version = '4.0.1-1';
  private key_contentVersion = 'content_version';
  private key_Navrail = 'navrail';
  private storageSub = new Subject<boolean>();

  constructor() {
  }

  initialize(): void {
    // this.setVersion(this.versions.getCurrentVersion());
    this.setNavRailOpen(false);
  }

  getNavRailState(): boolean {
    return localStorage.getItem(this.key_Navrail)?.toLowerCase() === 'true';
  }

  setNavRailOpen(value: boolean): void {
    localStorage.setItem(this.key_Version, this.val_Version);
    localStorage.setItem(this.key_Navrail, value.toString());
    this.storageSub.next(value);
  }

  watchStorage(): Observable<boolean> {
    return this.storageSub.asObservable();
  }
}
