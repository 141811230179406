<app-progress-spinner [loading]="!galleryReady"></app-progress-spinner>
<mat-sidenav-container>

  <mat-sidenav #sidenav fixedInViewport="true" fixedTopGap="64" mode="over" position="end" class="side">

    <section id="details" class="uilabContainer">

      <header class="uilabContainer--header">
        <div class="uilabContainer--header__identity">
          <span [innerHtml]="selectedIcon.svg | safeHtml" class="uilab-icon large"></span>
          <h6>{{ (selectedIcon.title != '') ? selectedIcon.title : '---' }}</h6>
        </div>
        <uilab-icon-button (click)="sidenav.close()">
          <uilab-icon slot="icon" icon="action--close"></uilab-icon>
        </uilab-icon-button>
      </header>

      <section class="uilabContainer--content">
        <app-icon-details [icon]="selectedIcon" [style]="filterArgs.iconstyle"></app-icon-details>
      </section>

    </section>

  </mat-sidenav>

  <mat-sidenav-content class="content">
    <div class="iconGallery--header">
      <div class="iconGallery--count">{{icons.length}} icons shown</div>
      <div class="iconGallery--version">Version {{iconService.version}}</div>
    </div>

    <div>
      <div class="icons-content">
        <app-icon-filter [args]="filterArgs" (onFilterChange)="FilterChange()"></app-icon-filter>

        <ng-container *ngIf="galleryReady; else galleryLoading">
          <ng-container *ngIf="!error; else errorMessage">
            <div class="iconGallery__show-labels" *ngFor="let c of categories">
              <h2 class="iconGallery--section-title">{{ c.category }}</h2>
              <div class="iconGallery--icon-list">
                <ng-container *ngFor="let i of icons">
                  <div *ngIf="i.category === c.category" class="iconGallery--icon icon" (click)="select(i)">
                    <span [innerHtml]="i.svg | safeHtml"></span>
                    <span class="iconGallery--icon-label">{{ i.title }}</span>
                  </div>
                </ng-container>
              </div>
            </div>
            <div *ngIf="icons.length == 0">
              <div class="notfound">No icons found matching pattern "{{ filterArgs.filter }}"</div>
            </div>
          </ng-container>
          <ng-template #errorMessage>
            <app-error [error]="errorMsg"></app-error>
          </ng-template>
        </ng-container>
        <ng-template #galleryLoading>
          <app-icon-gallery-blockframe></app-icon-gallery-blockframe>
        </ng-template>
      </div>
      <div class="uilabContainer--footer"></div>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>
