import { Component } from '@angular/core';
// import { LoggingService } from './services/ms/logging.service';
import { PageService } from './services/page/page.service';
import { PreferencesService } from './services/preferences/preferences.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  public busy: boolean = false;

  constructor(public pageService: PageService,
    public preferences: PreferencesService) {
      this.preferences.initialize();
      this.pageService.loading.subscribe(value => { this.busy = value; });
  }
}
