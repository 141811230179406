import {environment} from "src/environments/environment";

export class AppIcon {
  public name: string;
  public files: Array<string>;

  constructor(data: any) {
    this.name = data?.name;
    const fileList = [];
    if (data?.files) {
      for (let index = 0; index < data.files.length; index++) {
        // https://cdn.aveva.design//icons/applications/AI
        // const newURL = `${environment.cdn}/icons/applications/${this.name}/${data.files[index]}`;
        const newURL = `${data.files[index]}`;
        fileList.push(newURL);
      }
    }
    this.files = fileList;
  }
}
