import { Pipe, PipeTransform } from '@angular/core';
import { FilterChangeArgs } from 'src/app/models/filterChangeArgs.interface';
import { Icon } from 'src/app/models/icon.interface';

@Pipe({
  name: 'iconFilter',
  pure: false
})
export class IconFilterPipe implements PipeTransform {

  searchNewIndex(arr: Array<Icon>, newIcon: Icon): number {
    if(arr.length == 0) {
      return 0;
    }
    if(arr[0].category > newIcon.category ) {
      return 0;
    }
    if(arr[0].category == newIcon.category ) {
      if(arr[0].name > newIcon.name) {
        return 0;
      }
    }
    var i = 1;
    while(i < arr.length && !((arr[i].category > newIcon.category && arr[i-1].category <= newIcon.category) || 
      (arr[i].category == newIcon.category && arr[i-1].category == newIcon.category
        && arr[i].name > newIcon.name && arr[i - 1].name <= newIcon.name))) {
          i++;
        }
    return i;
  }

  transform(icons: Array<Icon>, args: FilterChangeArgs): Array<Icon> {
    var results = new Array<Icon>();
    if(icons) {
      icons.forEach(icon => {
        if(this.isFiltered(icon, args) && this.isSelectedCategory(icon, args) && this.isSelectedStyle(icon, args) ) {
          results.splice(this.searchNewIndex(results, icon), 0, icon);
        }
      });
    }
    return results;
  }

  isFiltered(icon: Icon, arg: FilterChangeArgs): boolean {
    var result = true;
    
    let hasName = (icon.name && icon.name.length > 0);
    let hasTitle = (icon.title && icon.title.length > 0);
    let hasCategory = (icon.category && icon.category.length >  0);
    let hasalternativelabels = (icon.alternativelabels && icon.alternativelabels.length > 0); 
    let hasTags = (icon.tags && icon.tags.length > 0);
    
    let matchName = (hasName && icon.name.toLowerCase().includes(arg.filter.toLowerCase()));
    let matchTitle = (hasTitle && icon.title.toLowerCase().includes(arg.filter.toLowerCase()));
    let matchCategory = (hasCategory && icon.category.toLowerCase().includes(arg.filter.toLowerCase()));
    let matchalternativelabels = (hasalternativelabels && icon.alternativelabels.toLowerCase().includes(arg.filter.toLowerCase()));
    let matchTags = (hasTags && icon.tags.toLowerCase().includes(arg.filter.toLowerCase()));
    
    // Filter Check
    if (!matchName && !matchTitle && !matchCategory && !matchalternativelabels && !matchTags) { 
      result = false; 
    }
    if (icon.size != arg.size) { 
      result = false;
    }
    return result;
  }

  isSelectedCategory(icon: Icon, arg: FilterChangeArgs): boolean {
    return (arg.category.toLowerCase() === 'all' || icon.category.toLowerCase().indexOf(arg.category.toLowerCase()) >= 0);
  }

  isSelectedStyle(icon: Icon, arg: FilterChangeArgs): boolean {
    return icon.iconstyle.includes(arg.iconstyle);
  }
}
