<div class="overlay" *ngIf="loading">
  <div class="center">
    <mat-progress-spinner
      class="example-margin"
      [color]="'primary'"
      [mode]="'indeterminate'"
      [value]="50">
    </mat-progress-spinner>
  </div>
</div>
