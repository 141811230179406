
export class Post {
    public date: string;
    public status: string;
    public title: string;
    public category: string;
    public isContentNode: boolean;
    public content: string;

    constructor(data?: any) {
        this.date = data?.date;
        this.status = data?.status;
        this.title = data?.title;
        if(data?.category) {
            this.category = data.category;
        } else {
            this.category = data?.categories?.nodes?.name;
        }
        this.isContentNode = data?.isContentNode;
        this.content = data?.content;
    }
}

