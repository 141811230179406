import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { BuildPage } from './pages/build/build.page';
import { CallbackPage } from './pages/callback/callback.page';
import { LandingPage } from './pages/landing/landing.page';

const routes: Routes = [
  { path: '', component: LandingPage },
  { path: 'callback', component: CallbackPage },
  { path: 'build', component: BuildPage, data: { page: 'home' } },
  { path: 'build/search', component: BuildPage, data: { page: 'search' } },
  {
    path: 'build/introduction/whats-new',
    component: BuildPage,
    data: { page: 'posts' },
  },
  {
    path: 'build/foundations/personas',
    redirectTo: 'build/user-research/personas/gallery',
    pathMatch: 'full',
  },
  { path: '**', component: BuildPage, data: { page: 'cms' } },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
