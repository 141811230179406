import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appicon-gallery-blockframe',
  templateUrl: './appicon-gallery-blockframe.component.html',
  styleUrls: ['./appicon-gallery-blockframe.component.scss'],
})
export class AppiconGalleryBlockframeComponent implements OnInit {
  public placeholder: any = { icons: [] };
  private numIcons = 42;

  constructor() {
    this.placeholder.icons = Array(this.numIcons)
      .fill(0)
      .map((x, i) => i);
  }

  ngOnInit(): void {}
}
