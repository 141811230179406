import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { IMenuCollection, ISelectedMenuLinkData } from '@uilab/core-angular';
import { MenusService } from 'src/app/services/menus/menus.service';
import { PageService } from 'src/app/services/page/page.service';
import { PreferencesService } from 'src/app/services/preferences/preferences.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { INavRailItems } from '@uilab/core-angular/app/organisms/nav-rail/nav-rail.interface';

@Component({
  selector: 'app-navrail',
  templateUrl: './navrail.component.html',
  styleUrls: ['./navrail.component.scss'],
})
export class NavrailComponent implements OnInit, OnDestroy {
  private _localStorageSubscription!: Subscription;
  public busy: boolean = false;
  private _isCollapsed: boolean = false;
  private inited: boolean = false;
  private home: INavRailItems = {
    name: 'Home',
    icon: 'navigation--home',
    linkData: {
      href: 'build/',
      data: '',
    },
    type: 'link',
  };

  private landing: INavRailItems = {
    name: 'ADS landing page',
    icon: 'navigation--back',
    linkData: {
      href: '/',
      data: '/',
    },
    type: 'link',
  };

  private divider: any = {
    type: 'divider',
    name: '',
  };
  activeRoute = ['/'];
  navRail: IMenuCollection[] = [this.home, this.divider];

  constructor(
    public menuService: MenusService,
    public pageService: PageService,
    public preferences: PreferencesService,
    private router: Router
  ) {
    this._isCollapsed = this.preferences.getNavRailState();
    if (this.menuService.loaded) {
      const menus = this.menuService.menus;
      this.setNavRail(menus);
      if (this.inited) {
        this.setActiveRoute();
      }
    } else {
      this.menuService.loading.subscribe((value) => {
        this.busy = value;
        if (!this.busy) {
          const menus = this.menuService.menus;
          this.setNavRail(menus);
          if (this.inited) {
            this.setActiveRoute();
          }
        }
      });
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveRoute();
      }
    });
    this.menuService.getNavigation();
  }

  ngOnInit(): void {
    if (!this.busy) {
      this.setActiveRoute();
    }
    this.inited = true;
    this.localStorageSubscription = this.preferences
      .watchStorage()
      .subscribe((value) => {
        this.isCollapsed = value;
      });
  }

  setActiveRoute(): void {
    let rte = '';
    let pathParts = window.location.pathname.split('/');
    if (pathParts[2]) {
      rte = `/${pathParts[2]}`;
      let newrte = rte.replace(`${environment.base}`, '');
      if (newrte.endsWith('/')) {
        newrte = newrte.slice(0, -1);
      }
      let item = this.navRail.find((i) => {
        return i.linkData?.href == newrte;
      });
      if (item) {
        rte = newrte;
      } else {
        rte = `${environment.base}${newrte}/`;
      }
    } else {
      rte = `${environment.base}/`;
    }

    let secondaryLevel = ``;

    if (pathParts?.[3]) {
      secondaryLevel = `${pathParts[1]}/${pathParts[2]}/${pathParts?.[3]}/`;
    }

    if (!pathParts?.[3] && pathParts?.[2]) {
      secondaryLevel = `${pathParts[1]}/${pathParts[2]}/`;
    }

    this.activeRoute = [rte, ...[secondaryLevel]];
  }

  navTo(dest: ISelectedMenuLinkData): void {
    const rte = dest.linkData.href;
    this.router.navigate([rte]);
    this.preferences.setNavRailOpen(false);
  }

  collapsed(value: any): void {
    this.preferences.setNavRailOpen(value);
  }

  setNavRail(menus: INavRailItems[]): void {
    // if (environment.version === 'preview') {
      menus.forEach((it) => this.navRail.push(it));
      this.navRail.push(this.divider);
      this.navRail.push(this.landing);
    // } else {
    //   this.navRail = menus;
    // }
  }

  get localStorageSubscription(): Subscription {
    return this._localStorageSubscription;
  }

  set localStorageSubscription(value: Subscription) {
    this._localStorageSubscription = value;
  }

  get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  set isCollapsed(value: boolean) {
    this._isCollapsed = value;
  }

  ngOnDestroy() {
    if (typeof this.localStorageSubscription !== 'undefined') {
      this.localStorageSubscription.unsubscribe();
    }
  }
}
