import { Component, Input } from '@angular/core';

import { ResearchCard } from '../../models/research.card.interface';
import { UilabBaseComponentsModule } from '@uilab/base-components-angular';

import { DatePipe, JsonPipe, NgIf, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-research-card',
  standalone: true,
  imports: [UilabBaseComponentsModule, TitleCasePipe, NgIf, DatePipe, JsonPipe],
  templateUrl: './research-card.component.html',
  styleUrl: './research-card.component.scss',
})
export class ResearchCardComponent {
  @Input() card!: ResearchCard;
}
