import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Post } from 'src/app/models/post.interface';
import { PathsService } from '../paths/paths.service';
import { environment } from 'src/environments/environment';
import { ApolloPostsService } from './apollo/apollo-posts.service';
import { PreferencesService } from '../preferences/preferences.service';
import { CdnPostsService } from './cdn/cdn-posts.service';

@Injectable({
  providedIn: 'root'
})
export class PostsService {
  public loading = new Subject<boolean>();
  public error: any;
  public loaded = false;
  public posts: Array<Post> = [];

  constructor(private apolloPosts: ApolloPostsService,
    private cdnPosts: CdnPostsService,
    private preferences: PreferencesService,
    private paths: PathsService) {}

  
  getPreviewPosts() {
    if(!this.loaded) {
      this.loading.next(true);
      this.apolloPosts.loading.subscribe((value) =>{
        this.error = this.apolloPosts.error;
        if (!value) {
          var posts = this.apolloPosts.data?.posts?.nodes;
          posts.forEach((p: any) => {
            var newPost = new Post(p);
            newPost.content = this.paths.cleanPage(environment.version, newPost.content, `${environment.server}/${environment.base}/whatsnew`, '', true);
            this.posts.push(newPost);
          });
        }
        this.loading.next(false);
      });
      this.apolloPosts.getData();
    }
  }

  async getPosts() {
    if(environment.version == 'preview') {
      this.getPreviewPosts();
    } else {
      const posts = await this.cdnPosts.getPostContent();
      if(posts) {
      posts.forEach((post: any) => {
        var newPost = new Post(post);
        newPost.content = this.paths.cleanPage(environment.version, newPost.content, `${environment.server}/${environment.base}/whatsnew`, '', true);
        this.posts.push(newPost);
    });
      this.loading.next(false);
    } else {
      this.error = `Could not read posts`;
    }
    }
  }
}
