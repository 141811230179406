export class Category {
    public category: string;
    public isSelected: boolean;

    constructor (data: any, isSelected: boolean = false) {
        this.category = data?.category;
        if(isSelected !== undefined) {
            this.isSelected = isSelected;
        } else {
            this.isSelected = data?.isSelected;
        }
    }
}