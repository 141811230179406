import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Page } from 'src/app/models/page.interface';
import { PathsService } from '../paths/paths.service';
import { ApolloTrackerService } from './apollo/apollo-tracker.service';

@Injectable({
  providedIn: 'root'
})
export class IcontrackerService {
  public loading = new Subject<boolean>();
  public error: any;
  public loaded = new CustomEvent("pageloaded");
  public page: Page = new Page();

  constructor(
    private apolloTrackerService: ApolloTrackerService,
    private paths: PathsService
  ) { }

  processData(data: any) {
    if(data) {
      this.page = new Page(data, '');
      this.page.content = this.paths.cleanPage('', this.page.content, this.page.url, '', true);
    } else {
      this.error = "Cannot load tracker";
    }
    this.loading.next(false);
  }

  getApolloData() {
    const _this = this;
    this.apolloTrackerService.loading.subscribe((value) => {
      if(!value) {
        this.error = _this.apolloTrackerService.error;
        let data = _this.apolloTrackerService.data;
        this.processData(data)
      }
    });
    this.apolloTrackerService.getTrackerContent();
  }

  getTracker(): void {
    this.loading.next(true);
    this.getApolloData();
  }
}
