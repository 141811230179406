import { Injectable } from '@angular/core';
import { Apollo, ApolloBase, gql } from 'apollo-angular';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApolloMenusService {
  public loading = new Subject<boolean>();
  public error: any;
  public loaded = false;
  public data: any;
  private apollo: ApolloBase;

  constructor(private apolloProvider: Apollo) {
    this.apollo = this.apolloProvider.use('cms');
  }

  getData(): void {
    if(!this.loaded) {
    this.loading.next(true);
    this.apollo
      .watchQuery({
        query: gql`{
          menu(id: "${environment.cms.menuRoot}") {
            name
            menuItems(where: {parentDatabaseId: 0}, first: 999) {
              nodes {
                label
                id
                parentId
                path
                adsMenu
                {
                  icon
                  isseparator
                }
                childItems(first: 999) {
                  nodes {
                    id
                    label
                    path
                    adsMenu
                    {
                      icon
                      isseparator
                    }
                  }
                }
              }
            }
          }
        }`,
      })
      .valueChanges.subscribe((result: any) => {
        this.error = result.error;
        if (!result.loading) {
          this.data = result?.data?.menu.menuItems.nodes;
          this.loading.next(false);
          this.loaded = true;
        }
      })
    }
  }
}
