import {Injectable} from '@angular/core';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  appInsights!: ApplicationInsights;

  constructor() {
    if (environment.appInsights.report) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsights.instrumentkey,
          enableAutoRouteTracking: true, // option to log all route changes
        },
      });
      this.appInsights.loadAppInsights();
    }
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    if (environment.appInsights.report) {
      this.appInsights.trackPageView({
        name: name,
        uri: url,
      });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (environment.appInsights.report) {
      this.appInsights.trackEvent({name: name}, properties);
    }
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    if (environment.appInsights.report) {
      this.appInsights.trackMetric(
        {name: name, average: average},
        properties
      );
    }
  }

  logException(exception: Error, severityLevel?: number) {
    if (typeof this.appInsights !== "undefined") {
      this.appInsights.trackException({
        exception: exception,
        severityLevel: severityLevel,
      });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({message: message}, properties);
  }
}
