import { Component, OnInit } from '@angular/core';
import { Post } from 'src/app/models/post.interface';
import { PostsService } from 'src/app/services/posts/posts.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.page.html',
  styleUrls: ['./whats-new.page.scss'],
})
export class WhatsNewPage implements OnInit {
  public loading = true;
  public posts = new Array<Post>();
  //public headerImage = `${environment.cdn}/${environment.version}/headerimages/wp-content/uploads/sites/2/2022/03/Whats-New-128x82-v2.png`;
  public headerImage = `${environment.cms.imgRoot}wp-content/uploads/sites/2/2022/03/Whats-New-128x82-v2.png`;
  constructor(public postService: PostsService) {}

  ngOnInit(): void {
    this.postService.getPosts();
    this.postService.loading.subscribe((value) => {
      this.loading = value;
      if (!this.loading) {
        this.posts = this.postService.posts;
      }
    });
  }
}
