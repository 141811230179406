<div class="static-card-container">
  @if (researchCards.length) {
  <div class="uilab-card uilab-card--static no-hover">
    <div class="uilab-card--content uilab-card--filter">
      <form [formGroup]="cardFilterForm">
        <div id="researchType">
          <mat-chip-listbox
            #chips
            aria-label="Research type selection"
            selectable
            [formControl]="researchType"
          >
            <mat-chip-option
              *ngFor="let type of researchTypes"
              [value]="type.value"
            >
              {{ type.label }}
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
        <div id="researchProduct">
          <mat-form-field appearance="fill" id="category">
            <mat-label>Products</mat-label>
            <mat-select [formControl]="researchProduct">
              <mat-option [value]="''">All</mat-option>
              @for (product of researchProducts; track product) {
              <mat-option [value]="product.label">{{
                product.label
              }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>

  @if (filtered.length) { @for (card of filtered; track card) {
  <app-research-card [card]="card"></app-research-card>
  } } @else {
  <div class="uilab-card uilab-card--static">
    <div class="uilab-card--content">
      <header class="uilab-card--header">
        <h4>No matches</h4>
      </header>
      Sorry, no studies match your criteria.
    </div>
  </div>
  } }
</div>
