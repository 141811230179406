export class Icon {
    public name: string;
    public number: string;
    public title: string;
    public category: string;
    public alternativelabels: string;
    public tags: string;
    public iconstyle: string;
    public size: string;
    public svg: string;

    constructor (data: any) {
        this.name = data?.name;
        this.number = data?.number;
        this.title = data?.title;
        this.category = data?.category;
        this.alternativelabels = data?.alternativelabels;
        this.tags = data?.tags;
        this.iconstyle = data?.iconstyle;
        this.size = data?.size;
        this.svg = data?.svg;
    }
}