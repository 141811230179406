export interface ResearchCard {
  date: string;
  researchers?: string;
  studyReportLink?: string;
  meetingReportLink?: string;
  prototypeLink?: string;
  menuOrder?: number;
  uri?: string;
  slug?: string;
  title?: string;
  content?: string;
  summary?: string;
  type?: StringUtil,
  product?: StringUtil
}

export class StringUtil {
  private _raw!: string[];

  public constructor(str: string[]) {
    this.raw = str;
  }

  set raw(value: string[]) {
    this._raw = value;
  }

  get raw(): string[] {
    return this._raw;
  }

  get formatted(): string[] {
    const formatted: string[] = [];
    this.raw.forEach((it) => {
      if(typeof it !== "undefined") {
        formatted.push(it.trim().toLowerCase().replace(/ /g, '_'));
      }
    });
    return formatted;
  }

  get joined(): string {
    return this.raw.join(", ");
  }
}
