<form 
  class="search"
  #filterFor="ngForm"
  (ngSubmit)="onSubmit()">

  <!-- <uilab-textfield
    #searchText
    name="text"
    type="search" 
    outlined="true"
    [(ngModel)]="value"
    (ngModelChange)="initSearch()">
    <uilab-icon slot="leading-icon" icon="content--tag-name"></uilab-icon>
  </uilab-textfield> -->

<mat-form-field class="example-form-field" appearance="outline">
  <!-- <mat-label>Search for icons</mat-label> -->
  <input matInput type="text" placeholder="Search application icons" name="text" [(ngModel)]="value" (ngModelChange)="initSearch()">
  <uilab-icon matPrefix icon="action--search"></uilab-icon>
  <uilab-icon-button *ngIf="value" matSuffix aria-label="Clear" title="Clear" (click)="resetForm()">
    <uilab-icon slot="icon" icon="action--close"></uilab-icon>
  </uilab-icon-button>
</mat-form-field>

</form>
