import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public copyrightYear!: string;

  constructor() { }

  public ngOnInit() {
    this.copyrightYear = new Date().getFullYear().toString();
  }

  link(dest: string) {
    window.location.href = dest;
  }

  internalLink(dest: string) {
    location.assign(dest);
  }
}
