<h1>Research, Design, Prototype, Build</h1>
<p>Design direction for effective and useable products at AVEVA</p>

<p>
  <img
    class="docs-figure alignnone size-full"
    src="/assets/ads-home-hero-color.png"
    alt=""
    width="760"
    height="380"
  />
</p>

<ui-search
  class="home__search"
  [hasContent]="!!value"
  (clearSearch)="value = ''"
>
  <input
    autocomplete="off"
    id="search"
    name="text"
    placeholder="Search site for text"
    type="text"
    [(ngModel)]="value"
    (keyup.enter)="sendToSearchPage()"
  />
</ui-search>
