import { Injectable } from '@angular/core';
import { Apollo, ApolloBase, gql } from 'apollo-angular';
import { Subject } from 'rxjs';
import { Category } from 'src/app/models/category.interface';
import { Icon } from 'src/app/models/icon.interface';
import { IconStyle } from 'src/app/models/iconstyle.interface';
import { Size } from 'src/app/models/iconSize.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IconsService {
  // Common
  public error = new Subject<boolean>();
  public errorMsg = '';
  // Version information
  public vloading = new Subject<boolean>();
  public version = 'Not loaded';
  public vloaded = new CustomEvent('iconsloaded');
  // Icons
  public loading = new Subject<boolean>();
  public icons = new Array<Icon>();
  public loaded = new CustomEvent('iconsloaded');
  // Icon Styles
  public sloading = new Subject<boolean>();
  public iconstyles = new Array<IconStyle>();
  public sloaded = new CustomEvent('styleloaded');
  // Categories
  public cloading = new Subject<boolean>();
  public categories = new Array<Category>();
  public cloaded = new CustomEvent('categoryloaded');
  // Sizes
  public zloading = new Subject<boolean>();
  public sizes = new Array<Size>();
  public zloaded = new CustomEvent('sizeloaded');

  private apollo: ApolloBase;
  constructor(private apolloProvider: Apollo) {
    this.apollo = this.apolloProvider.use('icons');
  }

  getAll() {
    this.getVersion();
    this.getIcons();
    this.getCategories();
    this.getIconStyles();
    this.getSizes();
  }

  getVersion() {
    this.apollo
      .watchQuery({
        query: gql`
        {
          version
        }`,
        })
        .valueChanges.subscribe((result: any) => {
          var data = result?.data;
          if(data) {
            this.version = data.version;
          }
          if(result.error) {
            this.errorMsg = result.errorMsg;
            this.error.next(result.error);
          } else {
            this.errorMsg = '';
            this.error.next(result.error);
          }
          if(!result.loading){
            dispatchEvent(this.vloaded);
          }
          this.vloading.next(false);
        })
  }

  getIcons() {
    this.icons = new Array<Icon>();
    this.apollo
      .watchQuery({
        query: gql`
        {
          icons {
            name
            number
            title
            category
            alternativelabels
            tags
            products
            iconstyle
            size
            svg
          }
        }`,
        })
        .valueChanges.subscribe((result: any) => {
          var data = result?.data;
          if(data?.icons) {
            for(var i=0; i < data.icons.length; i++) {
              var newIcon = new Icon(data.icons[i]);
              this.icons.push(newIcon);
            }
          }
          if(result.error) {
            this.errorMsg = result.errorMsg;
            this.error.next(result.error);
          } else {
            this.errorMsg = '';
            this.error.next(result.error);
          }
          if(!result.loading){
            dispatchEvent(this.loaded);
          }
          this.loading.next(false);
        })
  }

  getIconStyles() {
    this.iconstyles = new Array<IconStyle>();
    this.apollo
      .watchQuery({
        query: gql`
        {
          iconstyles {
            iconstyle
          }
        }`,
        })
        .valueChanges.subscribe((result: any) => {
          var data = result?.data;
          if(data?.iconstyles) {
            for(var i=0; i < data.iconstyles.length; i++) {
              this.iconstyles.push(new IconStyle(data.iconstyles[i]));
            }
        }
          if(result.error) {
            this.errorMsg = result.errorMsg;
            this.error.next(result.error);
          } else {
            this.errorMsg = '';
            this.error.next(result.error);
          }
          if(!result.loading){
            dispatchEvent(this.sloaded);
          }
          this.sloading.next(false);
        })
  }

  getCategories() {
    this.categories = new Array<Category>();
    this.apollo
      .watchQuery({
        query: gql`
        {
          categories {
            category
          }
        }`,
        })
        .valueChanges.subscribe((result: any) => {
          var data = result?.data;
          if(data?.categories) {
            for(var i=0; i < data.categories.length; i++) {
              this.categories.push(new Category(data.categories[i]));
            }
            this.categories.sort((a,b) => {
              if(a.category < b.category) return -1;
              if(a.category > b.category) return 1;
              return 0;
            });
          }
          if(result.error) {
            this.errorMsg = result.errorMsg;
            this.error.next(result.error);
          } else {
            this.errorMsg = '';
            this.error.next(result.error);
          }
          if(!result.loading){
            dispatchEvent(this.cloaded);
          }
          this.cloading.next(false);
        })
  }

  getSizes() {
    this.sizes = new Array<Size>();
    this.apollo
      .watchQuery({
        query: gql`
        {
          icons {
            size
          }
        }`,
        })
        .valueChanges.subscribe((result: any) => {
          var data = result?.data;
          if(data?.icons) {
            let tempData = [...new Set(data.icons.map((item: { size: any; }) => item.size))];
            for(var i=0; i < tempData.length; i++) {
              this.sizes.push(new Size({size:tempData[i]}));
            }
          }
          if(result.error) {
            this.errorMsg = result.errorMsg;
            this.error.next(result.error);
          } else {
            this.errorMsg = '';
            this.error.next(result.error);
          }
          if(!result.loading){
            dispatchEvent(this.zloaded);
          }
          this.zloading.next(false);
        })
  }

  getPNGUrl(icon: Icon, theme: string): string {
    let url = `${environment.cdn}/icons/png/${icon.iconstyle}/${icon.size}/${theme}/${icon.category}/${icon.name}.png`;
    return url;
  }

  getSVGUrl(icon: Icon, theme: string): string {
    let url = `${environment.cdn}/icons/svg/${icon.iconstyle}/${icon.size}/${theme}/${icon.category}/${icon.name}.svg`;
    return url;
  }

  getSpriteUrl(icon: Icon, theme: string): string {
    let url = `${environment.cdn}/icons/svg/sprites/uilab-icon-sprite-${icon.iconstyle}-symbols.svg`;
    return url;
  }
}
