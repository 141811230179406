import {Component, Input, OnInit} from '@angular/core';
import {PageChild} from 'src/app/models/page.interface';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {
  @Input() pages: PageChild[] = [];
  @Input() isStatic!: boolean;

  constructor() {
  }

  ngOnInit(): void {}
}
