import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Page} from 'src/app/models/page.interface';
import {environment} from 'src/environments/environment';
import {PathsService} from '../paths/paths.service';
import {PreferencesService} from '../preferences/preferences.service';
import {ApolloPageService} from './apollo/apollo-page.service';
import {CdnPageService} from './cdn/cdn-page.service';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  public loading = new Subject<boolean>();
  public error: any;
  public loaded = new CustomEvent("pageloaded");
  public page: Page = new Page();

  constructor(
    private preferences: PreferencesService,
    private cdn: CdnPageService,
    private apolloPageService: ApolloPageService,
    private paths: PathsService) {
  }


  path(data: any, path: string): string {
    var path = '';
    if (data) {
      if (data.parent) {
        if (data.parent.node.adsHierarchy.childPages == 'tabs') {
          path = data.parent.node.uri;
        }
      }
    }
    return path;
  }

  tab(data: any, path: string): string {
    var tab = '';
    path = path.replaceAll('"', '');
    if (data) {
      if (data.parent) {
        if (data.parent.node.adsHierarchy.childPages == 'tabs') {
          const parts = path.split('#')[0].split('/');
          if (parts[parts.length - 1] == '') {
            tab = parts[parts.length - 2];
          } else {
            tab = parts[parts.length - 1];
          }
        }
      }
    }
    return tab;
  }

  getApolloData(version: string, path: string) {
    const _this = this;
    this.apolloPageService.loading.subscribe((value) => {
      if (!value) {
        this.error = _this.apolloPageService.error;
        let data = _this.apolloPageService.data;
        const tab = this.tab(data, path);
        if (tab != '') {
          data = data.parent.node;
        }
        this.processData(version, data, tab);
      }
    });
    this.apolloPageService.getPageContent(path);
  }

  async getCDNData(version: string, path: string) {
    let data = await this.cdn.getPageContent(path);
    const tab = this.tab(data, path);
    if (tab != '') {
      path = this.path(data, path);
      data = await this.cdn.getPageContent(path);
    }
    if (data) {
      this.processData(version, data, tab);
    }
  }


  processData(version: string, data: any, tab: string) {
    if (data) {
      this.page = new Page(data, tab);
      this.page.content = this.paths.cleanPage(version, this.page.content, this.page.url, tab, true);
      for (var i = 0; i < this.page.children.length; i++) {
        this.page.children[i].content = this.paths.cleanPage(version, this.page.children[i].content, this.page.url, this.page.children[i].title.toLowerCase(), false);
      }
    } else {
      this.error = "404";
    }
    this.loading.next(false);
  }

  getData(path: string): void {
    if (environment.version == 'preview') {
      if ((path == '') || (path == `/${environment.base}`)) {
        path = '/home';
      }
      path = `"aveva-design-system` + path + `"`;
      this.getApolloData(environment.version, path);
    } else {
      if ((path == '') || (path == `/${environment.base}`)) {
        path = '';
      }
      path = path + '/';
      this.getCDNData(environment.version, path);
    }
  }

  getPageContent(path: string): void {
    this.loading.next(true);
    let url = path.split('#')[0];
    if (url) {
      url = url.replace(`${environment.base}/`, '');
      this.getData(url);
    }
  }
}
