import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {combineLatest} from 'rxjs';
import {Category} from 'src/app/models/category.interface';
import {FilterChangeArgs} from 'src/app/models/filterChangeArgs.interface';
import {Icon} from 'src/app/models/icon.interface';
import {IconStyle} from 'src/app/models/iconstyle.interface';
import {IconFilterPipe} from 'src/app/pipes/icon-filter/icon-filter.pipe';
import {IconsService} from 'src/app/services/icons/icons.service';

@Component({
  selector: '[appIcons]',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [IconFilterPipe],
})
export class IconsComponent implements OnInit {
  @ViewChild('sidenav') sideNav!: MatSidenav;
  @ViewChild('icon') icon!: HTMLElement;
  public icons: Array<Icon> = new Array<Icon>();
  public error = false;
  public errorMsg = '';
  public showDetails: boolean = false;
  public selectedIcon!: Icon;
  public iconstyles: Array<IconStyle> = [];
  public categories: Array<Category> = [];
  public filterArgs: FilterChangeArgs;
  public galleryReady: boolean = false;
  private defaultFilterArgs = {
    filter: '',
    category: '',
    iconstyle: '',
    size: '24'
  };

  constructor(
    public iconService: IconsService,
    private iconFilter: IconFilterPipe,
  ) {
    this.filterArgs = new FilterChangeArgs(this.defaultFilterArgs);
  }

  ngOnInit(): void {
    this.iconService.getAll();
    let result = combineLatest([
      this.iconService.vloading,
      this.iconService.loading,
      this.iconService.cloading,
      this.iconService.sloading,
      this.iconService.error,
    ]);
    result.subscribe(([iconsLoading, categoriesLoading, stylesLoading, error]) => {
      if (error) {
        this.errorMsg = this.iconService.errorMsg;
      } else {
        if (!iconsLoading && !categoriesLoading && !stylesLoading) {
          this.FilterIcons();
        }
      }
    });
    this.clearSelection();
  }

  getIcon(name: string, iconstyle: string, size: string): Icon | undefined {
    const icon = this.iconService.icons.find(i =>
      (i.name == name) && (i.iconstyle == iconstyle) && (i.size == size)
    );
    return icon;
  }

  select(icon: Icon): void {
    if (icon) {
      const selected = this.getIcon(icon.name, icon.iconstyle, '24');
      if (selected) {
        this.selectedIcon = selected;
        this.showDetails = true;
        this.sideNav.open();
        const details = document.getElementsByClassName('mat-drawer-inner-container')[0];
        if (details) {
          details.scrollTo({top: -1000});
        }
      }
    }
  }

  async getIcons() {
    this.icons = this.iconFilter.transform([...this.iconService.icons], this.filterArgs);
    this.categories = []

    let tempCategories = [...this.iconService.categories];
    for (let c = 0; c < tempCategories.length; c++) {
      if (this.icons.find(i => i.category == tempCategories[c].category)) {
        this.categories.push(tempCategories[c]);
      }
    }

    setTimeout(() => {this.galleryReady = true;},2000);
  }

  FilterIcons(): void {
    this.galleryReady = false;
    this.getIcons().then(() => {
      // console.log('Gallery ready');
    });
  }

  FilterChange() {
    this.FilterIcons();
    this.sideNav.close();
    this.clearSelection();
  }

  clearSelection(): void {
    const emptyIcon = {
      'name': ''
    };
    this.selectedIcon = new Icon(emptyIcon);
  }
}
