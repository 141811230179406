import { Component, OnInit } from '@angular/core';
import { IconproductsService } from 'src/app/services/icons/iconproducts.service';
import { DownloadService } from 'src/app/services/icons/download.service';

@Component({
  selector: '[appIconProducts]',
  templateUrl: './icon-products.component.html',
  styleUrls: ['./icon-products.component.scss']
})
export class IconProductsComponent implements OnInit {
  public products = [];

  constructor(
    public productservice: IconproductsService,
    private downloadservice: DownloadService,
  ) { }

  ngOnInit(): void {
    this.productservice.loading.subscribe(value => {
      if(!value){
        this.products = this.productservice.products;
      }
    })
    this.productservice.getProducts();
  }

  download(product: string): void {
    const src = `${this.productservice.productRepository}/${product}.zip`;
    // console.log(`Asking for ${src}`);
    this.downloadservice.saveZIP(src, product);
  }
}
