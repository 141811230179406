import { Injectable } from '@angular/core';
import * as streamSaver from 'streamsaver';
import * as Bowser from 'bowser';
import { environment } from 'src/environments/environment';
import { fileSave } from 'browser-fs-access';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  public error: object | null = null;
  constructor() {}

  canDownload(): boolean {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isValidBrowser = browser.satisfies({
      chrome: '>100.0.0',
      'Microsoft Edge': '>100.0.0',
    });
    return isValidBrowser || false;
  }

  cdnURL(
    format: string,
    style: string,
    size: string,
    color: string,
    category: string,
    name: string
  ) {
    if (format === 'ico') {
      return `${environment.cdn}icons/${format}/${style}/${color}/${category}/${name}.${format}`;
    } else {
      if (format === 'png') {
        return `${environment.cdn}icons/${format}/${style}/${size}/${color}/${category}/${name}.${format}`;
      } else {
        // svg
        return `${environment.cdn}icons/${format}/${style}/${size}/${category}/${name}.${format}`;
      }
    }
  }

  async saveSVGFile(source: string, name: string) {
    const response = await fetch(source, { method: 'GET' });
    const svg = await response.text();

    const uInt8 = new TextEncoder().encode(svg);
    const fileStream = streamSaver.createWriteStream(name, {
      size: uInt8.byteLength,
      writableStrategy: undefined,
      readableStrategy: undefined,
    });
    const writer = fileStream.getWriter();
    writer.write(uInt8);
    writer.close();
  }

  async saveSVG(style: string, size: string, category: string, name: string) {
    const url = this.cdnURL('svg', style, size, '', category, name);
    await this.saveSVGFile(url, `${name}.svg`);
  }

  async savePNGFile(source: string, name: string) {
    const response = await fetch(source, {
      method: 'GET',
      headers: {
        'Content-Type': 'image/png',
      },
    });
    const buffer = await response.arrayBuffer();
    const png = new Uint8Array(buffer);

    const fileStream = streamSaver.createWriteStream(name, {
      size: png.byteLength,
      writableStrategy: undefined,
      readableStrategy: undefined,
    });
    const writer = fileStream.getWriter();
    writer.write(png);
    writer.close();
  }

  async savePNG(
    style: string,
    size: string,
    color: string,
    category: string,
    name: string
  ) {
    const url = this.cdnURL('png', style, size, color, category, name);
    await this.savePNGFile(url, `${name}.png`);
  }

  async saveICOFile(source: string, name: string) {
    // console.log(`Source = ${source}`);
    const response = await fetch(source, {
      method: 'GET',
      headers: {
        'Content-Type': 'image/x-icon',
      },
    });
    // const buffer = await response.arrayBuffer();

    // const ico = new Uint8Array(buffer);
    // const fileStream = streamSaver.createWriteStream(name, {
    //   size: ico.byteLength,
    //   writableStrategy: undefined,
    //   readableStrategy: undefined,
    // });
    // const writer = fileStream.getWriter();
    // writer.write(ico);
    // writer.close();
    await fileSave(response, {fileName: name, extensions: ['.ico']});
  }

  async saveICO(style: string, color: string, category: string, name: string) {
    const url = this.cdnURL('ico', style, '', color, category, name);
    this.saveICOFile(url, `${name}.ico`);
  }

  async saveZIP(source: string, name: string) {
    window.open(source);
  }

  async saveFile(source: string) {
    let parts = source.split('/');
    const name = parts[parts.length - 1];
    parts = name.split('.');
    const ext = parts[parts.length - 1];
    switch (ext) {
      case 'ico':
        await this.saveICOFile(source, name);
        break;
      case 'png':
        await this.savePNGFile(source, name);
        break;
      case 'svg':
        await this.saveSVGFile(source, name);
        break;
      default:
        await this.saveZIP(source, '');
        break;
    }
  }
}
