import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.page.html',
  styleUrls: ['./callback.page.scss']
})
export class CallbackPage implements OnInit {

  constructor(
    public login: LoginService) {}

  ngOnInit(): void {
    this.login.callback();
  }
}
