import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GraphQLModule } from './services/graphql/graphql.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { environment } from 'src/environments/environment';

// Base Angular Modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './app-reroute-strategy';

// Auth0, Angular Material, 3rd party Components
import { AuthModule } from '@auth0/auth0-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
const AngularMaterial = [
  MatButtonModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatSortModule,
  MatTabsModule,
];

// UILab Components
import { UilabBaseComponentsModule } from '@uilab/base-components-angular';
import { UilabAppBarModule } from '@uilab/core-angular';
import { UilabNavRailModule } from '@uilab/core-angular';
const UILab = [
  UilabBaseComponentsModule,
  UilabAppBarModule,
  UilabNavRailModule,
];

// App Directives
import { CmsContentDirective } from './directives/cms-content.directive';
const AppDirectives = [CmsContentDirective];

// App Pipes
import { AppiconFilterPipe } from './pipes/appicon-filter/appicon-filter.pipe';
import { IconFilterPipe } from './pipes/icon-filter/icon-filter.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml/safeHtml.pipe';
const AppPipes = [AppiconFilterPipe, IconFilterPipe, SafeHtmlPipe];

// App Pages
import { BuildPage } from './pages/build/build.page';
import { LandingPage } from './pages/landing/landing.page';
import { SearchPage } from './pages/search/search.page';
import { CallbackPage } from './pages/callback/callback.page';
import { WhatsNewPage } from './pages/whats-new/whats-new.page';
const AppPages = [
  BuildPage,
  CallbackPage,
  LandingPage,
  SearchPage,
  WhatsNewPage,
  MaintenancePage,
];

// App Components
import { AppbarComponent } from './components/appbar/appbar.component';
import { AppIconsComponent } from './components/icons-app/icons-app.component';
import { AppiconDetailsComponent } from './components/appicon-details/appicon-details.component';
import { AppiconFilterComponent } from './components/appicon-filter/appicon-filter.component';
import { AppiconGalleryBlockframeComponent } from './components/appicon-gallery-blockframe/appicon-gallery-blockframe.component';
import { NavrailComponent } from './components/navrail/navrail.component';
import { CardsComponent } from './components/cards/cards.component';
import { ContentComponent } from './components/content/content.component';
import { CardComponent } from './components/card/card.component';
import { IconsComponent } from './components/icons/icons.component';
import { InnerContentComponent } from './components/inner-content/inner-content.component';
import { NavrailBlockframeComponent } from './components/navrail-blockframe/navrail-blockframe.component';
import { PageBlockframeComponent } from './components/page-blockframe/page-blockframe.component';
import { TabbedComponent } from './components/tabbed/tabbed.component';
import { FooterComponent } from './components/footer/footer.component';
import { LozengeComponent } from './components/lozenge/lozenge.component';
import { IconChipsComponent } from './components/icon-chips/icon-chips.component';
import { IconDetailsComponent } from './components/icon-details/icon-details.component';
import { IconFilterComponent } from './components/icon-filter/icon-filter.component';
import { IconGalleryBlockframeComponent } from './components/icon-gallery-blockframe/icon-gallery-blockframe.component';
import { IconThemeComponent } from './components/icon-theme/icon-theme.component';
import { ErrorComponent } from './components/error/error.component';
import { IconProductsComponent } from './components/icon-products/icon-products.component';
import { IconTrackerComponent } from './components/icon-tracker/icon-tracker.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { ErrorHandlerService } from './app.error';
import { MaintenancePage } from './pages/maintenance/maintenance.page';
import { Apollo } from 'apollo-angular';
import { ResearchCardsComponent } from './components/research-cards/research-cards.component';
import { SearchUiComponent } from './components/search/search.component';
import { HomePageComponent } from './pages/home/home.page';
import { UserResearchDetailComponent } from './components/user-research-detail/user-research-detail.component';
import { ResearchCardComponent } from './components/research-card/research-card.component';
const AppComponents = [
  AppbarComponent,
  AppIconsComponent,
  AppiconDetailsComponent,
  AppiconFilterComponent,
  AppiconGalleryBlockframeComponent,
  NavrailComponent,
  TabbedComponent,
  CardsComponent,
  ContentComponent,
  CardComponent,
  IconGalleryBlockframeComponent,
  InnerContentComponent,
  NavrailBlockframeComponent,
  PageBlockframeComponent,
  FooterComponent,
  LozengeComponent,
  IconsComponent,
  IconChipsComponent,
  IconDetailsComponent,
  IconFilterComponent,
  IconThemeComponent,
  ErrorComponent,
  IconProductsComponent,
  IconTrackerComponent,
  UserResearchDetailComponent,
];

@NgModule({
  declarations: [
    AppComponent,
    ...AppComponents,
    ...AppDirectives,
    ...AppPipes,
    ...AppPages,
    ProgressSpinnerComponent,
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        GraphQLModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        AuthModule.forRoot({
            domain: environment.auth.domain,
            clientId: environment.auth.clientID,
            authorizationParams: {
                redirect_uri: window.location.origin + environment.auth.callback,
            },
        }),
        ...AngularMaterial,
        ...UILab,
        ResearchCardsComponent,
        SearchUiComponent,
        HomePageComponent,
        ResearchCardComponent,
        NgOptimizedImage,
    ],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    Apollo,
  ],
  bootstrap: [AppComponent],
  exports: [CardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
