import { Component, OnInit } from '@angular/core';
import { PreferencesService } from 'src/app/services/preferences/preferences.service';

@Component({
  selector: 'app-navrail-blockframe',
  templateUrl: './navrail-blockframe.component.html',
  styleUrls: ['./navrail-blockframe.component.scss']
})
export class NavrailBlockframeComponent implements OnInit {
  public isCollapsed: boolean = false;

  constructor(
    public preferences: PreferencesService
  ) { 
    this.isCollapsed = this.preferences.getNavRailState();
  }

  ngOnInit(): void {
  }

}
