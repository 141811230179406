<app-appbar></app-appbar>

<section class="uilab-layout-container" *ngIf="loginService.isLoggedIn">
  <app-navrail class="uilab-layout-nav"></app-navrail>
  <section class="uilab-main-canvas">
    <app-home *ngIf="homePage"></app-home>
    <div appContent *ngIf="cmsPage || homePage"></div>
    <div appSearch *ngIf="searchPage"></div>
    <app-whats-new *ngIf="postPage"></app-whats-new>
  </section>
</section>
