<mat-sidenav-container>
  <mat-sidenav
    #sidenav
    fixedInViewport="true"
    fixedTopGap="64"
    mode="over"
    position="end"
    class="noscroll"
  >
    <section class="uilabContainer">
      <header class="uilabContainer--header">
        <div class="uilabContainer--header__identity">
          <img [src]="selectedIconPreview" class="uilabContainer--header__icon" alt="preview">
          <h6>{{ selectedIcon.name != "" ? selectedIcon.name : "---" }}</h6>
        </div>
        <uilab-icon-button (click)="sidenav.close()">
          <uilab-icon slot="icon" icon="action--close"></uilab-icon>
        </uilab-icon-button>
      </header>

      <section class="uilabContainer--content">
        <app-appicon-details [icon]="selectedIcon"></app-appicon-details>
      </section>
    </section>
  </mat-sidenav>

  <mat-sidenav-content class="content">
    <div class="iconGallery--header">
      <div class="iconGallery--count">{{ icons.length }} icons shown</div>
      <div class="iconGallery--version">Version {{ appIconService.version }}</div>
    </div>

    <div>
      <div class="icons-content">
        <app-appicon-filter [args]="filterArgs" (onFilterChange)="FilterChange()"></app-appicon-filter>

        <ng-container *ngIf="galleryReady; else galleryLoading">
          <ng-container *ngIf="!error; else errorMessage">
              <div>
              <div *ngFor="let i of icons">
                <p class="iconGallery--icon" (click)="select(i)">
                  <img [src]="i.files[i.files.length - 2]" class="iconGallery--img" alt="icon"/>
                  <span class="iconGallery--icon-label">{{ i.name }}</span>
                </p>
            </div>
            </div>
          </ng-container>
          <ng-template #errorMessage>
            <app-error [error]="errorMsg"></app-error>
          </ng-template>
        </ng-container>
        <ng-template #galleryLoading>
          <app-appicon-gallery-blockframe></app-appicon-gallery-blockframe>
        </ng-template>
      </div>
      <div class="uilabContainer--footer"></div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
