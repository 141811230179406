import { Component, OnInit } from '@angular/core';
import { IcontrackerService } from 'src/app/services/icontracker/icontracker.service';

@Component({
  selector: '[appIconTracker]',
  templateUrl: './icon-tracker.component.html',
  styleUrls: ['./icon-tracker.component.scss']
})
export class IconTrackerComponent implements OnInit {
  public content: string = '';

  constructor(
    public tracker: IcontrackerService
  ) { }

  ngOnInit(): void {
    this.tracker.loading.subscribe(value => {
      if(!value) {
        // Page is loaded
        const page = this.tracker.page;
        this.content = page.content;
      }
    });
    this.tracker.getTracker();
  }

}
