import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.page.html',
  styleUrls: ['./landing.page.scss']
})
export class LandingPage implements OnInit {
  splash = `${environment.cms.imgRoot}wp-content/uploads/2021/06/landing-page-composite.png`;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  toMarketing(): void {
    window.location.href = `https://aveva.frontify.com`;
  }

  toBuild(): void {
    this.router.navigate(['/build']);
  }
}
