import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterChangeArgs} from 'src/app/models/filterChangeArgs.interface';
import {Subject} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'app-icon-filter',
  templateUrl: './icon-filter.component.html',
  styleUrls: ['./icon-filter.component.scss']
})
export class IconFilterComponent implements OnInit {
  @Output() onFilterChange: EventEmitter<FilterChangeArgs> = new EventEmitter<FilterChangeArgs>();
  @Input() args: FilterChangeArgs = new FilterChangeArgs();
  public searchSubject: Subject<any> = new Subject();
  public searchForm!: FormGroup;

  constructor() {
  }

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      iconSearch: new FormControl(null, Validators.minLength(2)),
    });

    this.searchSubject.pipe(debounceTime(1000)).subscribe(() => {
      this.initSearch();
    });
  }

  onKeyUp(): void {
    this.searchSubject.next();
  }

  onKeyDown(): void {
    this.searchForm.clearValidators();
  }

  resetForm(): void {
    this.searchForm.reset();
    this.initSearch();
  }

  onSubmit(): void {
    this.initSearch();
  }

  FilterChange(args: FilterChangeArgs): void {
    this.args = args;
    this.onFilterChange.emit(this.args);
  }

  initSearch(): void {
    const searchFormValue = this.iconSearch?.value;
    this.args.filter = searchFormValue !== null ? searchFormValue : "";
    this.onFilterChange.emit(this.args);
  }

  get iconSearch(): FormControl {
    return this.searchForm.get("iconSearch") as FormControl;
  }
}
