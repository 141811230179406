export class DownloadableFiles {
    public path: string;
    public name: string;
    public ext: string;
    public size: number;
    public size_str: string;

    constructor (data: any) {
        this.path = data;
        this.name = '';
        this.ext = '';
        this.size = NaN;
        this.size_str = '';
        if(this.path) {
            let parts = this.path.split('/');
            this.name = parts[parts.length - 1];
            parts = this.name.split('.');
            this.ext = parts[parts.length - 1];
            parts = this.name.split('_');
            const sz = parts[parts.length - 1];
            if(sz) {
                if(!isNaN(parseInt(sz))) {
                    this.size = parseInt(sz);
                    this.size_str = this.size.toString();
                }
            }
        }
    }
}