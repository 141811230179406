<form class="search" #filterFor="ngForm" (ngSubmit)="onSubmit()" [formGroup]="searchForm">
  <mat-form-field appearance="outline">
    <!-- <mat-label>Search for icons</mat-label> -->
    <input
      matInput
      type="text"
      placeholder="Search for icons"
      name="text"
      formControlName="iconSearch"
      (keyup)="onKeyUp()"
      (keydown)="onKeyDown()"
    />
    <uilab-icon matPrefix icon="action--search"></uilab-icon>
    <uilab-icon-button
      *ngIf="iconSearch.value"
      matSuffix
      aria-label="Clear"
      title="Clear"
      (click)="resetForm()"
    >
      <uilab-icon slot="icon" icon="action--close"></uilab-icon>
    </uilab-icon-button>
  </mat-form-field>
</form>

<app-icon-chips
  class="iconGallery--filter"
  [filterOpts]="args"
  (onFilterChange)="FilterChange($event)"
>
</app-icon-chips>
