import {Component, Input, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() avatar: string = '';
  @Input() avatarsize: string = '';
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() imageSrc: string = '';
  @Input() description: string = '';
  @Input() guidanceStatus: string = '';
  @Input() scopePlatform: string = '';
  @Input() link: string = '';
  @Input() external: string = '';
  @Input() isStatic!: boolean;
  @Input() content!: string;
  public headerImage: boolean = false;
  public headerImageSourceUrl: string = '';
  public headerImageAltText: string = '';

  private erroneousWpContentString: string = '//wp-content';
  private correctWpContentString: string = '/wp-content';

  constructor() {
  }

  ngOnInit(): void {
    if (this.avatar != '') {
      this.headerImage = true;
      this.headerImageSourceUrl = environment.cms.imgRoot + this.avatar;

      const url = this.headerImageSourceUrl;
      if (url.includes(this.erroneousWpContentString)) {
        this.headerImageSourceUrl = url.replace(this.erroneousWpContentString, this.correctWpContentString);
      }
    } else {
      this.headerImage = false;
    }
  }

}
