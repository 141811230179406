<section class="">
  <h1>Search</h1>

  <div class="search-content">
    <form class="search" #filterFor="ngForm" (ngSubmit)="onSubmit()">
      <ui-search [hasContent]="!!value" (clearSearch)="resetForm()">
        <input
          autocomplete="off"
          id="search"
          name="text"
          placeholder="Search site for text"
          type="text"
          [(ngModel)]="value"
        />
      </ui-search>
    </form>
    <div class="searchResults">
      <div class="searchResults--loading" *ngIf="isSearching">
        <mat-progress-bar
          mode="indeterminate"
          [color]="loadingColor"
        ></mat-progress-bar>
      </div>

      <div
        class="searchResults--none"
        *ngIf="!initial && !isSearching && (searchResults.length == 0) && (value != '') && (error != '')"
      >
        <p><strong>{{error}}</strong></p>
      </div>

      <div
        class="searchResults--none"
        *ngIf="!initial && !isSearching && (searchResults.length == 0) && (value != '') && (error == '')"
      >
        <p>Couldn't find any matches for <strong>{{this.searched}}</strong>.</p>
      </div>

      <div
        class="searchResults--list"
        *ngIf="!initial && (searchResults.length > 0)"
      >
        <h3 *ngIf="isSearching" class="searchResults--title">
          Here's what we found so far:
        </h3>
        <h3 *ngIf="!isSearching" class="searchResults--title">
          Here's what we found:
        </h3>
        <dl class="searchResults--item" *ngFor="let page of searchResults">
          <dt class="searchResults--item--title route">
            <a href="{{envBase}}{{page.path}}">{{page.title}}</a>
          </dt>
          <dd class="searchResults--item--desc">
            <span *ngIf="page.collapsable">
              <span *ngIf="page.collapsed">
                <span *ngFor="let preview of page.collapsedPreviews">
                  <app-inner-content [content]="preview"></app-inner-content>
                </span>
                <p>
                  <span class="more" (click)="page.collapsed=false"
                    >Show More...</span
                  >
                </p>
              </span>
              <span *ngIf="!page.collapsed">
                <span *ngFor="let preview of page.previews">
                  <app-inner-content [content]="preview"></app-inner-content>
                </span>
                <p>
                  <span class="more" (click)="page.collapsed=true"
                    >Show Less...</span
                  >
                </p>
              </span>
            </span>
            <span *ngIf="!page.collapsable">
              <span *ngFor="let preview of page.collapsedPreviews">
                <app-inner-content [content]="preview"></app-inner-content>
              </span>
            </span>
          </dd>
        </dl>
        <div></div>
      </div>
    </div>
  </div>
  <div class="icon-note">
    <a>Note: Icons are not included in search. To find icons use the </a>
    <a href="/foundations/iconography/system-icons">icon search</a>
    <a>.</a>
  </div>
  <app-footer></app-footer>
</section>
