import { ResearchProduct } from '../../models/research.product.interface';
import { toLowerCaseReplaceSpaceWithDash } from './string.helpers';
import { ResearchCard, StringUtil } from '../../models/research.card.interface';

export const RESEARCH_PRODUCT = (item: string): ResearchProduct => {
  const formatted = toLowerCaseReplaceSpaceWithDash(item);
  const product: ResearchProduct = {
    id: formatted,
    label: item,
  };

  return product;
};

export const USER_RESEARCH_CARD = (child: any): ResearchCard => {
  return {
    date:
      typeof child.date !== 'undefined'
        ? new Date(child.date).getTime().toString()
        : new Date().getTime().toString(),
    researchers: child.researchers,
    studyReportLink: child.studyreportlink,
    meetingReportLink: child.meetingreportlink,
    prototypeLink: child.prototypelink,
    menuOrder: SET_MENU_ORDER(child.order),
    uri: child.uri,
    slug: child.slug,
    title: child.title,
    content: child.content,
    summary: child.summary,
    type: child?.researchtype ? new StringUtil(child.researchtype) : undefined,
    product: child?.researchproduct
      ? new StringUtil(child.researchproduct)
      : undefined,
  };
};

export const SET_MENU_ORDER = (menuOrder: number): number => {
  return menuOrder !== null ||
    menuOrder !== '' ||
    typeof menuOrder !== 'undefined'
    ? menuOrder
    : 0;
};
