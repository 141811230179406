import {IMenu, IMenuLinkData, IMenuType} from '@uilab/core-angular';
import {environment} from 'src/environments/environment';

export interface INavRailCollection extends IMenu {
  id: string;
  children?: INavRailCollection[];
}

export class NavRailItems implements INavRailCollection {
  public id: string;
  public children?: INavRailCollection[];
  public name: string;
  public linkData: IMenuLinkData;
  // public secondaryText?: string;
  // public tertiaryText?: string;
  public icon?: string;
  // public secondaryIcon?: string;
  public type?: IMenuType;
  // public listType?: IListType;
  // public avatarList?: boolean;
  // public meta?: string;
  // public badge?: IBadge;
  // public cssClass?: string;
  public canAccess?: boolean;

  constructor(data: any) {
    this.id = data?.id;
    this.name = data?.label;
    this.linkData = {
      href: data?.path.replace('/aveva-design-system/', environment.base + '/'),
    }
    this.icon = data?.adsMenu?.icon;
    this.type = data?.adsMenu?.isseparator ? "divider" : "link";
    this.canAccess = true;
  }
}
