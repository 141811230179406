import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-gallery-blockframe',
  templateUrl: './icon-gallery-blockframe.component.html',
  styleUrls: ['./icon-gallery-blockframe.component.scss']
})
export class IconGalleryBlockframeComponent implements OnInit {
  public placeholder:any = {galleries:[], icons: []};
  private numGalleries = 3;
  private numIcons = 42;

  constructor() {
    this.placeholder.galleries = Array(this.numGalleries).fill(0).map((x,i)=>i);
    this.placeholder.icons = Array(this.numIcons).fill(0).map((x,i)=>i);
  }

  ngOnInit(): void {
  }

}
