export class IconStyle {
    public iconstyle: string;
    public isSelected: boolean;

    constructor (data: any, isSelected: boolean = false) {
        this.iconstyle = data?.iconstyle;
        if(isSelected !== undefined) {
            this.isSelected = isSelected;
        } else {
            this.isSelected = data?.isSelected;
        }
    }
}