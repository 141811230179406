import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-build',
  templateUrl: './build.page.html',
  styleUrls: ['./build.page.scss'],
})
export class BuildPage {
  public cmsPage: boolean = false;
  public searchPage: boolean = false;
  public postPage: boolean = false;
  public homePage: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public loginService: LoginService
  ) {
    const data = this.route.snapshot.data['page'];
    if (data === 'cms') this.cmsPage = true;
    if (data === 'search') this.searchPage = true;
    if (data === 'posts') this.postPage = true;
    if (data === 'home') this.homePage = true;

    this.loginService.isAuthenticated.subscribe((value) => {
      if (!this.loginService.isLoggedIn) {
        this.loginService.login(this.router.url);
      }
    });
  }
}
