import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CantdownloadComponent } from 'src/app/dialogs/cantdownload/cantdownload.component';
import { AppIcon } from 'src/app/models/appicon.interface';
import { DownloadService } from 'src/app/services/icons/download.service';
import { DownloadableFiles } from 'src/app/models/downloadableFiles.interface';

@Component({
  selector: 'app-appicon-details',
  templateUrl: './appicon-details.component.html',
  styleUrls: ['./appicon-details.component.scss'],
})
export class AppiconDetailsComponent implements OnInit {
  public _icon: AppIcon = new AppIcon({ name: '' });
  @Input() set icon(value: AppIcon) {
    this._icon = value;
    this.files = Array<DownloadableFiles>();
    if (this._icon.files) {
      this._icon.files.forEach((file) => {
        this.files.push(new DownloadableFiles(file));
      });
      this.files.sort((a, b) => {
        return a.ext.localeCompare(b.ext) || a.size - b.size;
      });
      if (this.files[this.files.length - 1].ext == 'zip') {
        let zip = this.files.pop();
        if (zip) {
          zip.ext = 'zip (all)';
          this.files = [zip, ...this.files];
        }
      }
    }
  }
  get icon(): AppIcon {
    return this._icon;
  }
  public files = Array<DownloadableFiles>();

  constructor(
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private download: DownloadService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  copyBmp(): void {
    // this.clipboard.copy(this.getSVG());
    this.notify('Copied bitmap to clipboard');
  }

  cantdownload() {
    this.dialog.open(CantdownloadComponent, {
      width: '500px',
    });
  }

  downloadFile(file: string) {
    if (this.download.canDownload()) {
      this.download.saveFile(file);
    }
  }

  notify(msg: string): void {
    this.snackBar.open(msg, 'OK', {
      duration: 3000,
      horizontalPosition: 'center',
    });
  }
}
