import { Component, inject, Input } from '@angular/core';
import { Page } from '../../models/page.interface';
import { Router } from '@angular/router';
import { ResearchCard } from '../../models/research.card.interface';
import { USER_RESEARCH_CARD } from '../../shared/helpers/user-research.helpers';

export enum SortComparator {
  MENU,
  DATE,
}

@Component({
  selector: 'app-user-research-detail',
  templateUrl: './user-research-detail.component.html',
  styleUrl: './user-research-detail.component.scss',
})
export class UserResearchDetailComponent {
  public userResearch!: any;

  @Input() parent!: Page;

  private router = inject(Router);

  ngOnInit(): void {
    const DATA = this.parent?.node?.children?.nodes?.filter(
      (userResearch: ResearchCard) => {
        return `/build${userResearch.uri}` === `${this.router.url}/`;
      }
    );
    DATA.forEach((card: any) => {
      this.userResearch = USER_RESEARCH_CARD(card.adsType);
    });
  }
}
