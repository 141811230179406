import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

import { IMenu } from '@uilab/core-angular';
import { ISelectedMenuLinkData } from '@uilab/core-angular';
import {
  ILegal,
  ITopBarMenu,
} from '@uilab/core-angular/app/organisms/app-bar/app-bar.interface';
import { LoginService } from 'src/app/services/login/login.service';
import { environment } from 'src/environments/environment';
import { PreferencesService } from 'src/app/services/preferences/preferences.service';

@Component({
  selector: 'app-appbar',
  templateUrl: './appbar.component.html',
  styleUrls: ['./appbar.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class AppbarComponent {
  @ViewChild('appbar', {read: ElementRef})
  set appbar(v: ElementRef) {
    if (v) {
      const title = v.nativeElement.getElementsByClassName('uilab-top-app-bar__title')[0];
      if (title) {
        title.addEventListener('click', () => {
          this.router.navigate(['build']);
        });
      } else {
        console.error('no title');
      }
    }
  }
  @Input() busy: boolean = false;
  public userMenuOptions: IMenu[][] = [
    [
      {
        name: 'Profile loading...',
        secondaryText: 'Waiting for service',
        type: 'link',
        linkData: {
          href: 'profile',
        },
        icon: 'users--user-profile',
        listType: 'two-line',
        avatarList: true,
      },
    ],
    [
      {
        name: '',
        type: 'divider',
        linkData: {
          href: '',
        },
      },
      {
        name: 'Sign out',
        type: 'link',
        linkData: {
          href: 'sign-out',
          data: 'sign-out',
        },
        icon: 'action--sign-out',
      },
    ],
  ];
  public searchOption: ITopBarMenu = {
    name: 'Search',
    visible: false,
    icon: 'action--search',
    linkData: {
      href: environment.base + `/search`,
      data: 'Search',
    },
  };
  public legal: ILegal = {
    hasLegal: true,
    startYear: 2020,
    linkName: 'Legal',
    link: 'https://www.aveva.com/en/legal/',
    companyName: 'AVEVA Group Limited'
  };
  public searchString: string = '';
  public activeRoute: Array<string> = ['/build'];
  public mobileMenu: boolean = false;
  public isLoggedIn: boolean = false;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private loginService: LoginService,
    private preferencesService: PreferencesService
  ) {
    if (this.loginService.staticProfile) {
      this.userMenuOptions[0][0].name = this.loginService.staticProfile.email;
      this.userMenuOptions[0][0].secondaryText =
        this.loginService.staticProfile.name;
    }
    // console.log(`Appbar login = ${this.loginService.isLoggedIn}`);
    this.setLoginOptions(this.loginService.isLoggedIn);
    this.loginService.profile.subscribe((profile) => {
      this.userMenuOptions[0][0].name = profile.email;
      this.userMenuOptions[0][0].secondaryText = profile.name;
    });
    this.loginService.isAuthenticated.subscribe((value) => {
      this.setLoginOptions(value);
    });
  }


  setLoginOptions(value: boolean) {
    if (environment.featureFlags.auth) {
      this.isLoggedIn = value;
      const newSearch: ITopBarMenu = {
        name: this.searchOption.name,
        visible: value,
        icon: this.searchOption.icon,
        linkData: this.searchOption.linkData,
      }
      this.searchOption = newSearch;
    }
  }
  
  navTo(menuData: ISelectedMenuLinkData): void {
    alert(`navto ${JSON.stringify(menuData)}`);
  }

  toggleNav() {
    const navrail = this.preferencesService.getNavRailState();
    this.preferencesService.setNavRailOpen(!navrail);
  }

  menuItemSelected(data: any): void {
    switch (data.name) {
      case 'Sign out':
        this.loginService.logout();
        break;
      case 'search':
        this.router.navigate([`${environment.base}/search`]);
        break;
      default:
        break;
    }
  }
}
