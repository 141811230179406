import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inner-content',
  templateUrl: './inner-content.component.html',
  styleUrls: ['./inner-content.component.scss']
})
export class InnerContentComponent implements OnInit {
  @ViewChild('content') contentEl: ElementRef | null = null;
  @Input()
  get content() { return this._content; }
  set content(content: string) {
    if (this.contentEl) {
      this.contentEl.nativeElement.innerHTML = content;
    }
    this._content = content;
  }
  private _content: string = '';

  constructor(private router: Router) { }

  ngOnInit():void { }

  ngAfterViewInit(): void {
    if (this.contentEl) {
      this.contentEl.nativeElement.innerHTML = this._content;
    }
    this.scrollToSection();
  }

  scrollToSection():void {
    if (this.router.url.split('#')[1]) {
      var findSection = document.querySelector("#" + this.router.url.split('#')[1]);
      findSection?.scrollIntoView();
    }
  }

  internalLinkRouting(e:any): void {
    e.preventDefault();
    // this.router.navigateByUrl(e.target.closest('a').getAttribute('href'));
    var href = e.target.closest('a').getAttribute('href');
    var bits = href.split('#');
    var path = bits[0];
    var frag = (bits.length > 1) ? bits[1] : false;
    this.router.navigate([path], {fragment: frag});
  }
}
