import { Component, Input, OnInit } from '@angular/core';
import { PageChild } from '../../models/page.interface';
import { ResearchCard, StringUtil } from '../../models/research.card.interface';
import { UilabBaseComponentsModule } from '@uilab/base-components-angular';
import { MatChipsModule } from '@angular/material/chips';
import {
  DatePipe,
  JsonPipe,
  NgForOf,
  NgIf,
  TitleCasePipe,
} from '@angular/common';
import {
  RESEARCH_TYPES,
  ResearchType,
} from '../../models/research.type.interface';
import { ResearchProduct } from '../../models/research.product.interface';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ResearchCardComponent } from '../research-card/research-card.component';
import {
  RESEARCH_PRODUCT,
  USER_RESEARCH_CARD,
} from '../../shared/helpers/user-research.helpers';
import { toLowerCaseReplaceSpaceWithDash } from '../../shared/helpers/string.helpers';

export enum SortComparator {
  MENU,
  DATE,
}

@Component({
  selector: 'app-research-cards',
  standalone: true,
  imports: [
    UilabBaseComponentsModule,
    MatChipsModule,
    NgForOf,
    TitleCasePipe,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgIf,
    DatePipe,
    JsonPipe,
    ResearchCardComponent,
  ],
  templateUrl: './research-cards.component.html',
  styleUrl: './research-cards.component.scss',
})
export class ResearchCardsComponent implements OnInit {
  @Input() pageChildren!: PageChild[];
  public researchCards: ResearchCard[] = [];
  public filtered: ResearchCard[] = [];
  public researchTypes: ResearchType[] = [];
  public researchProducts: ResearchProduct[] = [];
  public cardFilterForm: FormGroup = new FormGroup<any>({
    researchType: new FormControl(null),
    researchProduct: new FormControl(''),
  });

  ngOnInit() {
    if (this.pageChildren) {
      this.pageChildren.forEach((child) => {
        if (child.researchproduct) {
          child.researchproduct.forEach((item) => {
            const product: ResearchProduct = RESEARCH_PRODUCT(item);
            if (!this.containsObject(product, this.researchProducts)) {
              this.researchProducts.push(product);
            }
          });
        }

        let researchType;
        if (child.researchtype) {
          researchType = new StringUtil(child.researchtype);
          child.researchtype.forEach((type) => {
            if (!this.researchTypes.some((item) => item.label === type)) {
              const DATA: ResearchType = this.createProductTypes(type);
              this.researchTypes.push(DATA);
            }
          });
        }

        let researchProduct;
        if (child.researchproduct) {
          researchProduct = new StringUtil(child.researchproduct);
        }

        this.researchCards.push(USER_RESEARCH_CARD(child));
      });

      this.sortCards(SortComparator.DATE);
      this.researchProducts.sort((a, b) =>
        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
      );
      this.filtered = this.researchCards;
    }

    this.cardFilterForm.valueChanges.subscribe(() => {
      this.filterResearchCards();
    });
  }

  containsObject(obj: any, list: any[]): boolean {
    let filtered = list.filter((it) => it.id === obj.id);
    return filtered.length > 0;
  }

  sortCards(comparator: number) {
    switch (comparator) {
      case SortComparator.MENU:
        this.researchCards.sort((a, b) => {
          return (a.menuOrder ?? 0) - (b.menuOrder ?? 0);
        });
        break;
      case SortComparator.DATE:
        this.researchCards.sort((a, b) => {
          return b.date.localeCompare(a.date);
        });
        break;
    }
  }

  filterResearchCards(): void {
    const product = this.researchProduct.value;
    const type = this.researchType.value;

    this.filtered = this.researchCards;

    if (product && !type) {
      this.filtered = this.researchCards.filter((card) =>
        card?.product?.formatted?.includes(
          toLowerCaseReplaceSpaceWithDash(product)
        )
      );
    }
    if (type && !product) {
      this.filtered = this.researchCards.filter((card) =>
        card?.type?.formatted?.includes(type)
      );
    }

    if (product && type) {
      this.filtered = this.researchCards.filter(
        (card) =>
          card?.product?.formatted?.includes(
            toLowerCaseReplaceSpaceWithDash(product)
          ) && card?.type?.formatted?.includes(type)
      );
    }
  }

  get researchType(): FormControl {
    return this.cardFilterForm.get('researchType') as FormControl;
  }

  get researchProduct(): FormControl {
    return this.cardFilterForm.get('researchProduct') as FormControl;
  }

  private createProductTypes(type: string): ResearchType {
    return {
      label: type,
      value: toLowerCaseReplaceSpaceWithDash(type),
      isSelected: false,
    };
  }
}
