import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchUiComponent } from 'src/app/components/search/search.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  standalone: true,
  host: { id: 'page_home' },
  imports: [SearchUiComponent, FormsModule],
})
export class HomePageComponent {
  public imageDomain = environment.cms.imgRoot;
  public value = '';

  private router = inject(Router);

  sendToSearchPage(): void {
    if (this.value) {
      this.router.navigate(['build', 'search'], {
        queryParams: { searchTerm: this.value },
      });
    }
  }
}
