import { Injectable } from '@angular/core';
import { Apollo, ApolloBase, gql } from 'apollo-angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApolloPageService {
  public loading = new Subject<boolean>();
  public error: any;
  public loaded = new CustomEvent('pageloaded');
  public data: any;
  private apollo: ApolloBase;

  constructor(private apolloProvider: Apollo) {
    this.apollo = this.apolloProvider.use('cms');
  }

  getPageContent(url: string) {
    this.loading.next(true);
    this.apollo
      .watchQuery({
        query: gql`
        {
          pageBy(uri: ${url}) {
            uri
            title
            content
            slug
            adsType {
              summary
              type
              exampleurl
              date
              researchers
              headerimage {
                sourceUrl
                altText
              }
            }
            adsHierarchy {
              childPages
            }
            adsGuidance {
              status
            }
            adsScope {
              platform
            }
            parent {
              node {
                uri
                ... on Page {
                  title
                  content
                  slug
                  adsType {
                    summary
                    type
                    exampleurl
                    date
                    researchers
                    headerimage {
                      sourceUrl
                      altText
                    }
                  }
                  adsHierarchy {
                    childPages
                  }
                  adsGuidance {
                    status
                  }
                  adsScope {
                    platform
                  }
                  parent {
                    node {
                      uri
                      ... on Page {
                        adsHierarchy {
                          childPages
                        }
                      }
                    }
                  }
                  children(first: 99) {
                    nodes {
                      ... on Page {
                        uri
                        title
                        content
                        slug
                        menuOrder
                        adsGuidance {
                          status
                        }
                        adsScope {
                          platform
                        }
                        adsType {
                          headerimage {
                            sourceUrl
                            altText
                          }
                          summary
                          type
                          exampleurl
                          date
                          researchers
                          studyreportlink
                          meetingreportlink
                          prototypelink
                          researchtype
                          researchproduct
                        }
                      }
                    }
                  }
                }
                ... on Page {
                  id
                  title
                }
              }
            }
            children(first: 99) {
              nodes {
                ... on Page {
                  uri
                  title
                  content
                  slug
                  menuOrder
                  adsGuidance {
                    status
                  }
                  adsScope {
                    platform
                  }
                  adsType {
                    headerimage {
                      sourceUrl
                      altText
                    }
                    summary
                    type
                    exampleurl
                    date
                    researchers
                    studyreportlink
                    meetingreportlink
                    prototypelink
                    researchtype
                    researchproduct
                  }
                }
              }
            }
          }
        }`,
      })
      .valueChanges.subscribe((result: any) => {
        this.error = result.error;
        if (!result.loading) {
          this.data = result.data.pageBy;
          this.loading.next(false);
        }
      });
  }
}
