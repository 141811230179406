export class FilterChangeArgs {
    public filter: string = '';
    public category: string = '';
    public iconstyle: string = '';
    public size: string = '';
    // Product

    constructor (data?:any) {
        this.filter = data?.filter;
        this.category = data?.category;
        this.iconstyle = data?.style;
        this.size = data?.size;
    }
}