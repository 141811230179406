<div class="skeleton">
  <div class="title shimmer"></div>
  <p>
    <span class="line shimmer"></span>
    <span class="line shimmer"></span>
    <span class="line shimmer"></span>
    <span class="line shimmer"></span>
    <span class="line shimmer"></span>
    <span class="line shimmer"></span>
  </p>
  <p>
    <span class="line shimmer"></span>
    <span class="line shimmer"></span>
  </p>
  <div class="subtitle shimmer"></div>
  <p>
    <span class="line shimmer"></span>
    <span class="line shimmer"></span>
    <span class="line shimmer"></span>
  </p>
  <div class="subtitle shimmer"></div>
  <p>
    <span class="line shimmer"></span>
    <span class="line shimmer"></span>
    <span class="line shimmer"></span>
    <span class="line shimmer"></span>
  </p>
</div>
