<div [ngClass]="{'card-container': !isStatic, 'static-card-container': isStatic}">
  <app-card *ngFor="let page of pages"
            [avatar]="page.headerImageSourceUrl"
            [title]="page.title"
            subtitle="Atom"
            [guidanceStatus]="page.guidanceStatus"
            [scopePlatform]="page.scopePlatform"
            [description]="page.summary"
            [link]='page.slug'
            [isStatic]="isStatic"
            [content]="page.content">
  </app-card>
</div>
