export class Size {
    public size: string;
    public isSelected: boolean;

    constructor (data: any, isSelected: boolean = true) {
        this.size = data?.size;
        if(isSelected) {
            this.isSelected = isSelected;
        } else {
            this.isSelected = data?.isSelected;
        }
    }
}