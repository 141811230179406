<mat-chip-listbox #chips aria-label="Style Selection" selectable>
  <mat-chip-option
    *ngFor="let s of styles"
    [selected]="(s.iconstyle == selectedStyle)"
    (click)="showStyle(s.iconstyle)">
    {{s.iconstyle | titlecase}}
  </mat-chip-option>
</mat-chip-listbox>

<mat-form-field appearance="fill" id="category">
  <mat-label>Category</mat-label>
  <mat-select (selectionChange)="showCategory($event)" [(ngModel)]="selectedCategory">
    <mat-option *ngFor="let c of categories" [value]="c.category">
      {{ c.category }}
    </mat-option>
  </mat-select>
</mat-form-field>
