import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NavRailItems } from '../../models/menu.interface';
import { ApolloMenusService } from './apollo/apollo-menu.service';
import { CdnMenuService } from './cdn/cdn-menu.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MenusService {
  public menus: NavRailItems[] = [];
  public loading = new Subject<boolean>();
  public error: any;
  public loaded = false;

  constructor(
    private apolloMenu: ApolloMenusService,
    private cdnMenu: CdnMenuService
  ) {}

  async getData() {
    if (environment.version == 'preview') {
      this.loading.next(true);
      this.apolloMenu.loading.subscribe((value) => {
        this.error = this.apolloMenu.error;
        if (!value) {
          this.menus = this.organize(this.apolloMenu.data);
          this.loading.next(false);
          this.loaded = true;
        }
      });
      this.apolloMenu.getData();
    } else {
      await this.cdnMenu.getData();
      this.menus = this.organize(this.cdnMenu.data);
      this.loading.next(false);
      this.loaded = true;
    }
  }

  getNavigation(): void {
    if (!this.loaded) {
      this.getData();
    }
  }

  organize(nodes: any): any {
    const nodesClone = JSON.parse(JSON.stringify(nodes));
    const menus: any[] = [];
    nodesClone.forEach((node: any) => {
      let icon = node.adsMenu.icon;
      // ToDo: Remove this when icon name is correct.
      // Temporary fix to replace erroneous icon name.
      if (icon === 'action--home') {
        node.adsMenu.icon = 'navigation--home';
      }
      if (icon === 'action--back') {
        node.adsMenu.icon = 'navigation--back';
      }
      // In preview mode, only add nodes without a parent id.
      if (environment.version == 'preview') {
        this.setMenu(node, menus);
      } else {
        this.setMenu(node, menus);
      }
    });
    return menus;
  }

  setMenu(node: any, menus: any) {
    const menu = new NavRailItems(node);
    menus.push(menu);
    if (node.childItems.nodes.length) {
      menu.children = [];
      node.childItems.nodes.forEach((child: any) => {
        menu.children?.push(new NavRailItems(child));
      });
    }
  }
}
