<div *ngIf="!isCollapsed">
  <div class="navrail">
    <div class="item-container">
      <div class="item">
        <div class="icon shimmer"></div>
        <div class="label shimmer"></div>
      </div>
    </div>
    <div class="uilab-nav-rail__divider"></div>
    <div class="item-container">
      <div class="item" *ngFor="let i of [1, 2, 3, 4, 5, 6]">
        <div class="icon shimmer"></div>
        <div class="label shimmer"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isCollapsed">
  <div class="navrail-small">
    <div class="item-container-small">
      <div class="item-small">
        <div class="icon shimmer"></div>
      </div>
    </div>
    <div class="uilab-nav-rail__divider"></div>
    <div class="item-container-small">
      <div class="item-small" *ngFor="let i of [1, 2, 3, 4, 5, 6]">
        <div class="icon shimmer"></div>
      </div>
    </div>
  </div>
</div>
